/**
 * 如果分页，则默认分页条数
 * 等同于offset
 */
export const PAGE_SIZE = 10;
export const VERSION_NUM = '4.2.22';

const bodyWidth = getBodyWidth();

if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
        value: function (searchElement, fromIndex) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }

            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            var n = fromIndex | 0;

            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                // c. Increase k by 1.
                // NOTE: === provides the correct "SameValueZero" comparison needed here.
                if (o[k] === searchElement) {
                    return true;
                }
                k++;
            }

            // 8. Return false
            return false;
        }
    });
}

/**
 * 是否是服务器端
 */
function isServerSide() {
    return typeof window === "undefined";
}
/**
 * 获取屏幕宽度
 */
function getBodyWidth() {
    let width = 0;
    if (isServerSide()) {
        width = 1024;
    } else {
        width = document.getElementsByTagName("body")[0].clientWidth;
    }
    return width;
}

/**
 * 获取屏幕高度
 */

/**
 * 用户授权信息cookie
 * 是否为空来表示是否登录
 * 有接口会进行4分钟一次的轮询
 */
export const COOKIE_DS_UID_SIGN = "AITEPDynSign";
// 获取用户信息
export const COOKIES_DS_USER_INFO = "UserInfo";

export const URL_PREFIX = "/api";
/**
 *  请求返回成功 静态字段 方便比较
 */
export const SUCCESS = "success";

export const CODE_UN_LOGIN = 1500;
export const CODE_NO_RIGHT = 1001;
export const CODE_MAX_AMOUNT = 1002;

/**
 * 详情中 两个固定字段
 */
export const COUNTRY_YIBAO = "国家医保(乙)";
export const COUNTRY_JIYAO = "国家基药";

// cookiee
export const STORAGE_USER_TOKEN_KEY = "access-token";

/**
 * 浏览器宽度
 */

export const BODY_WIDTH = bodyWidth;

// 中文  此处的value其实跟接口有关系
export const PRODUCT_LIST_CN_VIEW_TYPE = [{
    value: "eslist",
    label: "按批文浏览"
}, {
    value: "list_by_drug_name",
    label: "按药品名称浏览"
}, {
    value: "list_by_manufacture",
    label: "按企业名称浏览"
}];
/**
 * 欧盟 viewType
 */
export const PRODUCT_LIST_EU_VIEW_TYPE = [{
    value: "eslist",
    label: "按申请号浏览"
}, {
    value: "list_by_company",
    label: "按企业名称浏览"
}, {
    value: "list_by_substance",
    label: "按成分浏览"
}];
/**
 * 美国viewtype
 */
export const PRODUCT_LIST_US_VIEW_TYPE = [{
    value: "eslist",
    label: "按申请号浏览"
}, {
    value: "list_by_drug_name",
    label: "按药名浏览"
}, {
    value: "list_by_company",
    label: "按企业浏览"
}];

/**
 * 中国临床试验
 */

export const PRODUCT_LIST_CT_CN_VIEW_TYPE = [{
    value: "eslist",
    label: "按标题浏览"
}, {
    value: "list_by_drug_name",
    label: "按药名浏览"
}];
/**
 * 全球临床
 */
export const PRODUCT_LIST_CT_GLOBAL_VIEW_TYPE = [{
    value: "eslist",
    label: "按标题浏览"
}, {
    value: "list_by_intervention",
    label: "按药名浏览"
}];

/**
 * 日本viewtype
 */
export const PRODUCT_LIST_JP_VIEW_TYPE = [{
    value: "eslist",
    label: "按批文浏览"
}, {
    value: "list_by_company",
    label: "按企业名称浏览"
}];
/**
 * 国内注册受理
 */
export const REG_CN_LIST_VIEW_TYPE = [{
    value: "eslist",
    label: "注册信息浏览"
}, {
    value: "list_by_drug_name",
    label: "竞争品种分析"
}, {
    value: "list_by_enterprise",
    label: "企业竞争力分析"
}];

/**
 * 中国新药浏览
 */
export const CN_NEW_DRUGS_LIST_VIEW_TYPE = [{
    value: "eslist",
    label: "按药品浏览"
}, {
    value: "list_by_enterprise",
    label: "按企业浏览"
},];

let PROTOCOL = window.location.protocol;

export let curHost = window.location.hostname + (window.location.port ? ":" + window.location.port : "");
export const curHostName = curHost;
// 本地调试不支持https
if (curHost === "db2.drugsea.cn") {
    PROTOCOL = "http:";
}

// api访问不经过CDN
if (curHost === 'db.drugsea.cn') {
    // curHost = 'db3.drugsea.cn';
}

/**
 * 默认接口请求地址
 */
export const BASE_HOST = PROTOCOL + "//" + curHost + "/api2";

export const AUTH_HOST = PROTOCOL + "//" + curHost;

/**
 * 全局搜索接口地址
 */
export const GLOBAL_BASE_HOST = PROTOCOL + "//" + curHost + "/api2";
/**
 * 用户登录默认接口
 */
export const LOGIN_BASE_HOST = PROTOCOL + "//" + curHost;
/**
 * 注册查询、生物签发
 */
export const QY_BASE_HOST = PROTOCOL + "//" + curHost + "/api2";

/**
 * 默认cookie的domain
 * 支持domain类型： db.drugsea.cn, localhost, 127.0.0.1
 */
const myRe3 = /^(\d+\.\d+\.\d+\.\d+)[:]*(\d*)$|[.]*(\w+\.\w+)[:]*(\d*)$|(\w+)[:]*(\d*)$/;
console.log("curHost in constants", curHost);
const myArray3 = myRe3.exec(curHost);
let domain = "";
if (typeof myArray3[1] !== "undefined") {
    domain = myArray3[1];
} else if (typeof myArray3[3] !== "undefined") {
    domain = "." + myArray3[3];
} else if (typeof myArray3[5] !== "undefined") {
    domain = myArray3[5];
}

export const COOKIES_DOMAIN = domain;
export const COOKIES_FULL_DOMAIN = window.location.hostname;

export const REQUSET_URLS = {
    updateShowColumns: `${AUTH_HOST}/api/update/show/columns`, // 更新数据库显示列
    submitFeedback: `${BASE_HOST}/save/user/feedback`, // 首页提交反馈
    onkey: `${BASE_HOST}/onekey`, // 一键搜索 列表
    mysearch: `${BASE_HOST}/mysearch`, // 我的筛选 列表
    deletMySearch: `${BASE_HOST}/mysearch/delete/`, // 删除我的筛选
    generic_cn: `${BASE_HOST}/generic/cn/list`, // 仿制药
    generic_cn_detail: `${BASE_HOST}/generic/cn/detail/`, // 仿制药详情
    product_cn: `${BASE_HOST}/product/cn/`, // 中国上市药品
    product_en: `${GLOBAL_BASE_HOST}/ema_drugs/`, // 欧盟上市药品
    product_us: `${GLOBAL_BASE_HOST}/fda_drugs/`, // 美国上市药品
    product_jp: `${GLOBAL_BASE_HOST}/jp_drugs/`, // 日本上市药品
    product_cn_detail: `${BASE_HOST}/product/cn/detail/`, // 中国上市药品 详情
    product_eu_detail: `${GLOBAL_BASE_HOST}/ema_drugs/detail/`, // 欧盟药品详情
    product_us_detail: `${GLOBAL_BASE_HOST}/fda_drugs/detail/`, // 美国药品详情
    product_us_detail_app: `${GLOBAL_BASE_HOST}/fda_drugs/applno/`, // 美国药品详情 批准历史
    product_us_detail_others: `${GLOBAL_BASE_HOST}/fda_drugs/others`, // 美国药品详情 专利、独占、等效药品接口
    global_drug_detail: `${GLOBAL_BASE_HOST}/global_drugs/detail/`, // 全球药物 详情
    global_drug_cn_list1: `${BASE_HOST}/product/cn/glist`, // 全球药物 中国上市1 翻页
    global_drug_west_list: `${GLOBAL_BASE_HOST}/western_drugs/list`, // 全球药物 欧美上市 翻页
    global_china_reg_list: `${QY_BASE_HOST}/b/global_drugs/drugreg/cn`, // 全球药物 中国注册申报 翻页
    update_linked_to_xui: `${GLOBAL_BASE_HOST}/update/linked/to/xui`, // 更新数据库的linked_to_xui标记

    // 注册
    china_reg_list: `${QY_BASE_HOST}/b/drugreg/cn/`, // 中国注册受理
    global_get_patent_list: `${GLOBAL_BASE_HOST}/global_drug_patents/list`, // 全球药物 专利简析
    reg_cn_detail: `${QY_BASE_HOST}/b/drugreg/cn/detail/`, // 中国注册受理详情
    reg_cn_xui: `${BASE_HOST}/global/drugs/detail`, // 全球上市情况
    reg_cn_generic_drugs: `${BASE_HOST}/b/drugreg/generic/drugs`, // 仿制药竞品情况
    reg_cn_related_ct: `${BASE_HOST}/c/cde/ct/info`, // 相关临床情况
    reg_cn_slh_timeline: `${BASE_HOST}/b/drugreg/timeline`, // 审评时间轴
    china_new_drug_list: `${QY_BASE_HOST}/b/new/drug/cn/`, // 中国新药
    china_product_list: `${BASE_HOST}/product/cn/list`, // 中国上市品种
    china_progress_list: `${QY_BASE_HOST}/b/new/drug/progress/list`, // 新药研发进展
    china_progress_delete: `${QY_BASE_HOST}/b/new/drug/progress/delete`, // 删除适应症的进展
    china_progress_stop: `${QY_BASE_HOST}/b/new/drug/progress/stop`, // 修改终止标记
    save_reference_data: `${BASE_HOST}/add/reference`, // 保存参考资料
    yaohai_reference_list: `${BASE_HOST}/reference/list`, // 参考资料列表
    yaohai_jicai_list: `${BASE_HOST}/jicai/list`, // 集采中标列表
    yaohai_delete_reference: `${BASE_HOST}/delete/reference`, // 删除参考资料
    yaohai_reference_management_list: `${BASE_HOST}/reference/management/list`,

    // 受理统计
    reg_data_by_year: `${QY_BASE_HOST}/b/drugreg/cn/column/year`, // 受理统计by year
    reg_data_by_month: `${QY_BASE_HOST}/b/drugreg/cn/column/month`, // 受理统计by month
    reg_rang_by_company: `${QY_BASE_HOST}/b/drugreg/cn/column/enterprise`, // 排名 按公司
    reg_rang_by_cat: `${QY_BASE_HOST}/b/drugreg/cn/column/drug_name`, // 排名 按品种
    reg_data_by_apply_type: `${QY_BASE_HOST}/b/drugreg/cn/column/apply_type`, // 按品种
    reg_data_by_reg_type: `${QY_BASE_HOST}/b/drugreg/cn/column/register_type`, // 按注册类型
    reg_data_by_atc: `${QY_BASE_HOST}/b/drugreg/cn/column/ATC_code`, // 按治疗领域
    reg_drug_name_auto: `${QY_BASE_HOST}/b/drugreg/cn/autosuggest/drug_name`, // 按照关键词查询品类
    reg_enterprise_auto: `${QY_BASE_HOST}/b/drugreg/cn/autosuggest/enterprise`, // 按照关键词查询品类
    // 监测追踪
    monitor_list_cn: `${BASE_HOST}/product/cn/list`, // 监测追踪 中国
    monitor_list_us: `${GLOBAL_BASE_HOST}/fda_drugs/list`, // 监测追踪 美国
    monitor_list_eu: `${GLOBAL_BASE_HOST}/ema_drugs/list`, // 监测追踪 欧盟
    monitor_list_jp: `${GLOBAL_BASE_HOST}/jp_drugs/list`, // 监测追踪 美国
    monitor_list_ct_cn: `${QY_BASE_HOST}/c/cde/ct/list`, // 临床试验 中国
    monitor_list_ct_gb: `${BASE_HOST}/us/ct/list`, // 临床试验 全国
    monitor_list_drug_reg: `${QY_BASE_HOST}/b/drugreg/cn/list`, // 注册受理监测
    monitor_list_trace_cn: `${QY_BASE_HOST}/b/product/trace/list/slh`, // 注册受理追踪 中国
    monitor_list_trace_enterprise: `${QY_BASE_HOST}/b/product/trace/list/enterprise`, // 注册受理追踪 企业
    monitor_list_trace_drug_name: `${QY_BASE_HOST}/b/product/trace/list/drug_name`, // 注册受理追踪 药品名称
    monitor_list_trace_today_count: `${QY_BASE_HOST}/b/product/trace/new/count`, // 今日注册受理追踪数量
    monitor_list_tracking_city: `${QY_BASE_HOST}/c/zhaobiao/status`, // 招标动态追踪城市
    monitor_list_tracking_city_new: `${QY_BASE_HOST}/c/zhaobiao/status/count`, // 今日城市新增动态数量
    monitor_list_tracking_list: `${QY_BASE_HOST}/c/zhaobiao/new/`, // 招标动态列表

    // 用户登录
    verification_code: `${LOGIN_BASE_HOST}/check/verfication/code`, // 检查验证码
    login: `${LOGIN_BASE_HOST}/wechat/login`, // 登陆
    accountLogin: `${BASE_HOST}/yaohai/account/login`, // 账号密码登录
    auth: `${AUTH_HOST}/check/yaohai/auth`, // 刷新cookie,
    yaohai_auth: `${AUTH_HOST}/check/yaohai/auth`, // 浏览器登录成功后，抓取用户认证信息,
    get_dr_token: `${BASE_HOST}/get/dr/token`, // 获取跳转到新药记的带token地址
    globalSearch: `${GLOBAL_BASE_HOST}/global_drugs/list`, // 全局搜索
    check_user_qrscan: `${AUTH_HOST}/check/qy/qrscan`, // 检查用户qrscan 结果
    gtRegister: `${AUTH_HOST}/check/register`, // 注册geetest

    // 申请试用高级功能
    apply_advance: `${QY_BASE_HOST}/c/drugsea/apply/ent`,
    get_apply_info: `${QY_BASE_HOST}/c/drugsea/apply/ent/info`,

    // 全国销售
    sales_cn: `${QY_BASE_HOST}/c/drug/hosp`, // 全国销售
    sales_global: `${BASE_HOST}/drug/annual_report`, // 年报数据
    sales_all_trend: `${QY_BASE_HOST}/c/drug/hosp/rank/years`, // 整体统计
    sale_hosp_rank_company: `${QY_BASE_HOST}/c/drug/hosp/rank/company`, // 排行 企业
    sale_hosp_rank_drug_name: `${QY_BASE_HOST}/c/drug/hosp/rank/drug_name`, // 排行药品
    sale_hosp_rank_prd: `${QY_BASE_HOST}/c/drug/hosp/rank/product`, // 排行 品种
    sale_hosp_rank_city: `${QY_BASE_HOST}/c/drug/hosp/rank/city`, // 城市排行
    sale_hosp_rank_atc: `${QY_BASE_HOST}/c/drug/hosp/rank/ATC_code`, // 治疗领域
    sale_hosp_company_auto: `${QY_BASE_HOST}/c/drug/hosp/autosuggest/company`, // 企业自动提示
    sales_hosp_drug_name_auto: `${QY_BASE_HOST}/c/drug/hosp/autosuggest/drug_name`, // 药品自动提示
    sale_hosp_drug_pie: `${QY_BASE_HOST}/c/drug/hosp/rank/company`, // 药品分析

    // 中国临床
    product_cn_ct: `${QY_BASE_HOST}/c/cde/ct/`, // 中国临床
    product_us_ct: `${BASE_HOST}/us/ct/`, // 全球临床

    // 招标
    get_zhaobiao_list: `${BASE_HOST}/es/zhaobiao/list`, // 招标

    // 医保目录
    get_yibao_list: `${BASE_HOST}/yibao/list`, // 医保目录

    // 基药目录
    get_jiyao_list: `${BASE_HOST}/jiyao/list`, // 基药目录

    // 说明书
    get_shuomingshu_list: `${GLOBAL_BASE_HOST}/sms/eslist`, // 说明书

    //
    get_shuomingshu_detail: `${GLOBAL_BASE_HOST}/sms/detail/`, // 说明书

    //
    get_cncompany_list: `${BASE_HOST}/enterprise/eslist`, // 国内企业

    //
    get_cncompany_detail: `${BASE_HOST}/enterprise/detail/`, // 国内企业
    get_equity_info: `${BASE_HOST}/enterprise/equity/`, // 企业股权关系

    //
    saveUserInfo: `${BASE_HOST}/user/save`, // 保存用户信息
    //
    saveMonitor: `${BASE_HOST}/update/monitor`, // 保存追踪信息
    //
    getMonitor: `${BASE_HOST}/user/monitor/status`, // 获取追踪信息

    //
    ct_cn_detail: `${QY_BASE_HOST}/c/cde/ct/detail/`, // 中国临床详情

    //
    ct_us_detail: `${BASE_HOST}/us/ct/detail/`, // 全球临床详情

    //
    get_selected_items: `${BASE_HOST}/zhaobiao/compare`, // 招标对比

    //
    getTracking: `${QY_BASE_HOST}/b/product/trace/`, // 获取追踪信息
    //
    getZhaoBiaoTracking: `${QY_BASE_HOST}/c/zhaobiao/status`, // 获取招标追踪信息

    //
    unsubscribeCity: `${QY_BASE_HOST}/c/zhaobiao/subscribe/0/`, // 取消订阅城市

    subscribeCity: `${QY_BASE_HOST}/c/zhaobiao/subscribe/1/`, // 订阅城市
    updateTracking: `${QY_BASE_HOST}/b/product/trace`, // 更新跟踪

    // 生物制品签发
    get_bioIssue_list: `${QY_BASE_HOST}/c/pqf/eslist`, // 批签发检索结果列表
    get_bioIssue_filter_type: `${QY_BASE_HOST}/c/pqf/list/source`, // 批签发，获取条件筛选的类别
    get_bioIssue_detail: `${QY_BASE_HOST}/c/pqf/detail/`, // 批签发详情

    // 通用数据获取接口
    get_aggs_list: `${LOGIN_BASE_HOST}/api3/aggs/list`, // 药海通用数据
    get_dbs_eslist: `${LOGIN_BASE_HOST}/api3/dbs/eslist`, // 药海通用数据
    get_dbs_list: `${LOGIN_BASE_HOST}/api3/dbs/list`, // 药海通用数据
    update_drug_law_tags: `${BASE_HOST}/h/drug/law/tags/update`, // 更新数据库显示列
    update_drug_law_category_tag: `${BASE_HOST}/h/drug/law/category/tag`, // 更新数据库显示列
    update_zb_news_tags: `${BASE_HOST}/c/zb/news/tags/update`, //
    update_zb_news_category_tag: `${BASE_HOST}/c/zb/news/category/tag`, //
    update_se_notice_tags: `${BASE_HOST}/h/se/notice/tags/update`, //
    update_se_notice_category_tag: `${BASE_HOST}/h/se/notice/category/tag`, //

    add_one_row: `${LOGIN_BASE_HOST}/api3/data/add`, // 增加一行的通用接口

    drugseaPost: `${BASE_HOST}`,

    update_dp2_task: `${BASE_HOST}/dp2/task/update`,

    update_nmpa_data: `${QY_BASE_HOST}/f/update/nmpa/data`, // 更新跟踪
    update_data_request: `${QY_BASE_HOST}/data/request/add`, // 更新后台数据请求

    // 全库搜索
    mdbIndexSearch: `${BASE_HOST}/yaohai/es/mdb/index/search/v1`,
    getHotWords: `${BASE_HOST}/get/hot/words`,
    postHotWord: `${BASE_HOST}/update/hot/word`,
};

/**
 * 日本上市 titles
 */

export const JP_TABLE_TITLES = {
    sales_name: "商品名",
    drug_name: "药品名称",
    manufacture: "生产企业",
    approve_date: "批准日期",
    package_insert_HTML: "说明书",
    interview_form_PDF: "IF文件"
};

// 菜单

export const MENUS = [
    // {
    //   key: 'HP',
    //   name: '首页',
    //   route: '/monitorAndTracking',
    //   subMenu: null
    // },
    {
        key: "MAT",
        name: "监测追踪",
        route: "/monitorAndTracking",
        subMenu: null
    }, {
        key: "RR",
        name: "药品注册",
        route: "/reg/cn",
        subMenu: null
    }, {
        key: "SS",
        name: "药品销售",
        route: "/sales/cn",
        subMenu: null
    },

    {
        key: "MMP",
        name: "数据库导航",
        isMore: true,
        subMenu: [{
            key: "ZhuCe",
            name: "注册情报",
            subMenu: [{
                key: "RR3",
                name: "一致性评价产品",
                route: "/in/yzpj_products",
                isNew: true,
            }, {
                key: "RR2",
                name: "药品注册审评",
                route: "/reg/cn"
            }, {
                key: "CNYFB",
                name: "原料药、药用辅料和药包材登记信息公示",
                route: "/in/cde_yfb_registration"
            }, {
                key: "CDE_SEQ_ANA",
                name: "CDE审评序列分析系统",
                route: "/v3/cde_sequence_analyzor.pl"
            }, {
                key: "CDE_SXH_ZC",
                name: "【随心汇】药品注册审评",
                route: "/aggs/drugreg_cn"
            }, {
                key: "CDE_MD_ZC",
                name: "器械注册审评综合库",
                route: "/v3/search_medical_device.pl"
            }]
        }, {
            key: "ShangSHi",
            name: "上市情报",
            subMenu: [{
                key: "MCN",
                name: "国内上市药品",
                route: "/product/cn"
            }, {
                key: "MCN2",
                name: "【随心汇】国内上市品种",
                route: "/aggs/product_cn"
            }, {
                key: "MUS",
                name: "美国上市药品(含橙皮书)",
                route: "/product/us"
            }, {
                key: "MUS2",
                name: "美国药品NDC数据库",
                route: "/in/fda_ndc"
            }, {
                key: "MUS3",
                name: "美国DMF数据库",
                route: "/in/fda_dmf",
                isNew: true,
            }, {
                key: "MUR",
                name: "欧盟EMA上市药品",
                route: "/product/eu"
            }, {
                key: "MUR2",
                name: "欧盟HMA上市药品",
                route: "/in/hma"
            }, {
                key: "MUK",
                name: "英国上市药品",
                route: "/in/uk_emc"
            }, {
                key: "MJ",
                name: "日本上市药品",
                route: "/product/jp"
            }, {
                key: "MJ_DMF",
                name: "日本DMF数据库",
                route: "/in/japan_dmf",
                isNew: true,
            }, {
                key: "MHK",
                name: "香港上市药品",
                route: "/in/hk_doh"
            }, {
                key: "PCM",
                name: "香港注册中成药",
                route: "/in/cmchk_pcm",
            }, {
                key: "MTW",
                name: "台湾上市药品",
                route: "/in/tw_fda"
            }, {
                key: "DPD",
                name: "加拿大上市药品",
                route: "/in/dpd",
            }
            ]
        }, {
            key: "ShiChang",
            name: "市场情报",
            subMenu: [{
                key: "SS2",
                name: "国内医院药品销售",
                route: "/sales/cn"
            }, {
                key: "SS3",
                name: "全终端药品销售",
                route: "/in/drugsales"
            }, {
                key: "SAR",
                name: "年报药品销售",
                route: "/sales/global"
            }, {
                key: "MMT",
                name: "国内药品中标",
                route: "/zhaobiao"
            }, {
                key: "JiCai",
                name: "国家与地方集采数据库",
                route: "/in/jicai"
            }, {
                key: "R2",
                name: "生物制品批签发",
                route: "/bio/issue"
            }]
        }, {
            key: "ZhunRu",
            name: "市场准入",
            subMenu: [{
                key: "MIC",
                name: "医保目录",
                route: "/in/yibao"
            }, {
                key: "MBM",
                name: "基药目录",
                route: "/in/jiyao"
            },

            {
                key: "NHSACODE",
                name: "医保药品分类与代码",
                route: "/in/nhsa_code"
            }, {
                key: "NHSA_HERBS",
                name: "中药饮片信息",
                route: "/in/nhsa_herbs",
            }, {
                key: "NHSA_HOSPITAL",
                name: "医疗机构制剂信息",
                route: "/in/nhsa_hospital_prepration",
                isNew: false,
            }
            ]
        }, {
            key: "CanKao",
            name: "药政参考",
            subMenu: [

                {
                    key: "CNRD",
                    name: "仿制药参比制剂目录",
                    route: "/in/cn_reference_drugs"
                }, {
                    key: "CNRDP",
                    name: "仿制药参比制剂目录(公示)",
                    route: "/in/cn_reference_drugs_publicity"
                }, {
                    key: "CNOB",
                    name: "中国上市化学药品目录集",
                    route: "/in/cn_orange_book"
                }, {
                    key: "GVDRG",
                    name: "政府用药目录",
                    route: "/in/gov_drugs"
                }
            ]
        }, {
            key: "HYCanKao",
            name: "行业参考",
            subMenu: [{
                key: "MU_TARGETS",
                name: "药物靶点数据库",
                route: "/in/targets"
            }, {
                key: "MMI",
                name: "药品说明书",
                route: "/sms"
            }, {
                key: "CNC",
                name: "中国医药企业",
                route: "/enterprise"
            }, {
                key: "ZLSM",
                name: "药品专利声明",
                route: "/in/zlsm"
            }, {
                key: "ZLDJ",
                name: "药品专利信息公示",
                route: "/in/zldj"
            }, {
                key: "ZYCSJK",
                name: "中药材数据库",
                route: "/in/herbs",
                isNew: false,
            }, {
                key: "ZYFJSJK",
                name: "中药方剂数据库",
                route: "/in/herb_formulas",
                isNew: false,
            }, {
                key: "YHCKZL",
                name: "药海参考资料",
                route: "/references",
                isNew: false,
            }]
        }, {
            key: "NMPADB",
            name: "NMPA基础库",
            subMenu: [{
                key: "NMPA_GC",
                name: "国产药品",
                route: "/in/nmpa_guochan"
            }, {
                key: "NMPA_JK",
                name: "进口药品",
                route: "/in/nmpa_jinkou"
            }, {
                key: "NMPA_TCM_PROTECTION",
                name: "中药保护品种",
                route: "/in/nmpa_tcm_protection",
                isNew: false,
            }, {
                key: "NMPA_TCM_GRANULES",
                name: "中药配方颗粒备案信息",
                route: "/in/nmpa_tcm_granules",
                isNew: false,
            }, {
                key: "NMPA_REG_PATENT",
                name: "药品注册相关专利信息",
                route: "/in/nmpa_reg_patent",
                isNew: false,
            }, {
                key: "NMPA_GC_FOOD",
                name: "国产保健食品注册",
                route: "/in/nmpa_tsspxx_gc"
            }, {
                key: "NMPA_JK_FOOD",
                name: "进口保健食品注册",
                route: "/in/nmpa_tsspxx_jk"
            }, {
                key: "MED_DEV",
                name: "国产器械(注册)",
                route: "/in/medical_device"
            }, {
                key: "MED_DEV_JK",
                name: "进口器械(注册)",
                route: "/in/medical_device_jinkou"
            }, {
                key: "MED_DEV_BA",
                name: "国产器械(备案)",
                route: "/in/medical_device_beian"
            }, {
                key: "MED_DEV_JK_BA",
                name: "进口器械(备案)",
                route: "/in/medical_device_jinkou_beian"
            }, {
                key: "NMPA_GMP",
                name: "GMP认证",
                route: "/in/nmpa_gmp"
            }]
        }, {
            key: "DrugNews",
            name: "药闻速递",
            subMenu: [{
                key: "ZB_NEWS",
                name: "全国招标动态",
                route: "/in/zb_news"
            }, {
                key: "STOCK_NEWS",
                name: "医药上市公司公告",
                route: "/in/se_notice"
            }, {
                key: "DRUG_LAW",
                name: "药品法规知识库",
                route: "/in/drug_law"
            }]
        }, {
            key: "ClinicalTrials",
            name: "临床试验",
            subMenu: [{
                key: "MDC",
                name: "国内临床试验",
                route: "/ct/cn"
            }, {
                key: "MGC",
                name: "全球临床试验",
                route: "/ct/us"
            }]
        }]
    }
];

export const SALES_CN_LIST_CONDITION_YEARS = {
    list: [{
        id: 19,
        years: "2023"
    }, {
        id: 18,
        years: "2022"
    }, {
        id: 17,
        years: "2021"
    }, {
        id: 16,
        years: "2020"
    }, {
        id: 15,
        years: "2019"
    }, {
        id: 14,
        years: "2018"
    }, {
        id: 13,
        years: "2017"
    }, {
        id: 12,
        years: "2016"
    }, {
        id: 11,
        years: "2015"
    }, {
        id: 10,
        years: "2014"
    }, {
        id: 9,
        years: "2013"
    }, {
        id: 8,
        years: "2012"
    }, {
        id: 7,
        years: "2011"
    }, {
        id: 6,
        years: "2010"
    }, {
        id: 5,
        years: "2009"
    }, {
        id: 4,
        years: "2008"
    }, {
        id: 3,
        years: "2007"
    }, {
        id: 2,
        years: "2006"
    }, {
        id: 1,
        years: "2005"
    }],
    type: "multiple"
};

export const SALES_CN_LIST_CONDITION_QUARTER = {
    list: [{
        id: 1,
        quarter: "1季度"
    }, {
        id: 2,
        quarter: "2季度"
    }, {
        id: 3,
        quarter: "3季度"
    }, {
        id: 4,
        quarter: "4季度"
    }],
    type: "multiple"
};

export const SALES_CN_LIST_CONDITION_DRUG_TYPE = {
    list: [{
        id: 1,
        drug_type: "化药"
    }, {
        id: 2,
        drug_type: "中药"
    }, {
        id: 3,
        drug_type: "生物制品"
    }, {
        id: 4,
        drug_type: "药用辅料"
    }, {
        id: 5,
        drug_type: "其他"
    }],
    type: "multiple"
};

export const SALES_CN_LIST_CONDITION_ROUTES = {
    list: [{
        id: 18,
        administration_route: "口服"
    }, {
        id: 13,
        administration_route: "注射"
    }, {
        id: 17,
        administration_route: "口颊用药"
    }, {
        id: 6,
        administration_route: "舌下"
    }, {
        id: 7,
        administration_route: "耳用"
    }, {
        id: 11,
        administration_route: "滴耳"
    }, {
        id: 8,
        administration_route: "眼用"
    }, {
        id: 12,
        administration_route: "滴眼"
    }, {
        id: 16,
        administration_route: "吸入"
    }, {
        id: 1,
        administration_route: "鼻用"
    }, {
        id: 10,
        administration_route: "滴鼻"
    }, {
        id: 9,
        administration_route: "直肠"
    }, {
        id: 2,
        administration_route: "阴道"
    }, {
        id: 14,
        administration_route: "植入"
    }, {
        id: 15,
        administration_route: "外用"
    }, {
        id: 19,
        administration_route: "冲洗"
    }, {
        id: 5,
        administration_route: "诊断剂"
    }, {
        id: 3,
        administration_route: "透析"
    }, {
        id: 4,
        administration_route: "辅料"
    }, {
        id: 20,
        administration_route: "不易直接使用或非用于人体者"
    },],
    type: "multiple"
};

export const SALES_CN_ATC_CODE = {};
const cityList = [
    "福建省",
    "江苏省",
    "新疆维吾尔自治区",
    "浙江省",
    "湖北省",
    "黑龙江省",
    "吉林省",
    "广东省",
    "河北省",
    "重庆市",
    "安徽省",
    "山西省",
    "上海市",
    "河南省",
    "湖南省",
    "山东省",
    "云南省",
    "陕西省",
    "内蒙古自治区",
    "辽宁省",
    "四川省",
    "天津市",
    "北京市",
    "贵州省"
];

export const SALES_SELECT_CITY_LIST = getSalesSelectCityList();

function getSalesSelectCityList() {
    const res = [];
    cityList.map(item => {
        res.push({
            value: item,
            label: item
        });
    });
    return res;
}

function getSalesCityList() {
    const res = [];
    cityList.map((item, index) => {
        res.push({
            id: index,
            city: item
        });
    });
    return res;
}

export const SALES_CN_LIST_CONDITION_CITY = {
    list: getSalesCityList(),
    type: "multiple"
};

export const BOOL_FIELDS = [
    "is_site_inspection",
    "is_yizhipingjia",
    "is_passed_yizhi",
    "is_license_holder",
    "is_47",
    "rows_excluded",
    "is_clinical_audit"
];

export const FIELD_NAMES = {
    drug_name: "药品名称",
    manufacture: "企业名称",
    company: "企业名称",
    enterprise: "企业名称",
    specification: "规格",
    Strength: "规格",
    auth_num: "批准文号",
    dosage_form: "剂型",
    drug_type: "药品类型",
    yibao: "医保类型",
    jiyao: "基药类型",
    listing_date: "上市日期",
    year: "上市日期",
    new_drug_type: "新药类型",
    source: "国产/进口",
    is_passed_yizhi: "已通过一致性评价",
    ATC_code: "ATC分类",
    special_condition: "特殊条件",
    brand_name: "活性成分",
    product_number: "申请号",
    review_type: "审批程序",
    status: "市场状态",
    MarketingStatus: "市场状态",
    ApplNo: "申请号",
    RLD: "是否参比制剂",
    ApplyType: "申请类型",
    apply_type: "申请类型",
    SubmissionClassification: "化学类型",
    reviewPriorityOrphanStatus: "评审类型",
    ReviewPriorityOrphanStatus: "评审类型",
    city: "城市",
    quarter: "季度",
    years: "年份",
    ct_status: "状态",
    class: "分类",
    legal_representative: "法人代表",
    study_phase: "临床阶段",
    study_type: "研究类型",
    insurance_level: "医保类型",
    slh: "受理号",
    indication: "适应症",
    rd_status: "研发状态",
    transact_status: "办理状态",
    conclusion: "评审结论",
    is_yizhipingjia: "一致评价新受理",
    is_clinical_audit: "临床数据现场核查",
    is_site_inspection: "注册生产现场检查",
    is_47: "4+7城市集采中选清单",
    is_license_holder: "上市许可持有人品种",
    special_list: "特殊品种",
    register_type: "注册分类",
    slh_types: "申报类型",
    key_info: "关键信息",
    prov_abs: "来源省份",
    target: "靶点",
    brief_introduction: "药品简介",
    province: "地区",
    title: "标题",
    identifier: "登记号",
    register_num: "登记号",
    interventions: "相关药物",
    study_sponsor: "试验单位",
    first_received_date: "首次登记日期",
    announce_date: "公示日期",
    approve_date: "批准日期",
    undertake_date: "承办日期",
    status_start_date: "状态日期",
    rows_excluded: "排除备案",
    search_mode: "搜索模式",
    viewType: "浏览类型",
    issuance_amount: "签发量",
    issue_conclusion: "签发结论",
    issue_date: "签发日期",
    product_name: "产品名称",
    issuing_agency: "批签发机构",
    exact: "精确查询",
    query: "全文搜索",
    item: "全文搜索",
    is_orange_book: "目录集",
    sumBy: '汇总依据',
    sumTarget: '汇总对象',
    general_name_cn: '成分词',
    license_holder_cn: '持证商',
    license_holder: '持证商',
    std_specification: '标准规格(XD)',
    yibao_dosage: '医保剂型',
    in_sfda: '是否有效',
    date_of_issue: '发证日期',
    date_of_expiry: '有效期至',
    first_approve_date: '首次上市日期',
    active_substance: '活性成分',
    quality_level: '质量层次',
    category: '中标项目',
    std_catalog_version: '版本',
    notice_year: '年份',
    execute_status: '执行状态',
    innovation_degree: '创新程度',
    general_name_count: '同成分厂家数',
    product_count: '同品种厂家数',
    dosage_count: '同规格厂家数',
    apply_ctc_num: '申报临床厂家数',
    apply_listing_num: '申报生产厂家数',
    market_num: '已上市厂家数',
};

// ATC大类
export const ATC01_TREEDATA = [{
    label: "消化道及代谢(A)",
    value: "A",
    key: "A"
}, {
    label: "血液和造血器官(B)",
    value: "B",
    key: "B"
}, {
    label: "心血管系统(C)",
    value: "C",
    key: "C"
}, {
    label: "皮肤病用药(D)",
    value: "D",
    key: "D"
}, {
    label: "生殖泌尿系统和性激素(G)",
    value: "G",
    key: "G"
}, {
    label: "非性激素和胰岛素类的激素类系统用药(H)",
    value: "H",
    key: "H"
}, {
    label: "系统用抗感染药(J)",
    value: "J",
    key: "J"
}, {
    label: "抗肿瘤药和免疫机能调节药(L)",
    value: "L",
    key: "L"
}, {
    label: "肌肉-骨骼系统(M)",
    value: "M",
    key: "M"
}, {
    label: "神经系统(N)",
    value: "N",
    key: "N"
}, {
    label: "抗寄生虫药、杀虫药和驱虫药(P)",
    value: "P",
    key: "P"
}, {
    label: "呼吸系统(R)",
    value: "R",
    key: "R"
}, {
    label: "感觉器官(S)",
    value: "S",
    key: "S"
}, {
    label: "其它(V)",
    value: "V",
    key: "V"
}, {
    label: "中药(Z)",
    value: "Z",
    key: "Z"
}];

export const CHECK_AMOUNT = {
    amount9: 20,
    amount10: 20,
    amount15: 5000,
    amount11: 5000,
    amount12: 5000,
    amount13: 8000,
    amount14: 300,
    amount201: 5000,
    amount202: 5000,
    amount203: 8000,
    amount205: 5000,
    amount204: 300
};
