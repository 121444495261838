// import Fingerprint2 from "fingerprintjs2";
import Cookies from "js-cookie";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import qs from "qs";
import moment from "moment";
//import uuidV4 from "uuid/v4";
import { v4 as uuidv4 } from 'uuid';
import queryString from "query-string";
import { COOKIES_DOMAIN, COOKIES_DS_USER_INFO, COOKIE_DS_UID_SIGN } from "./constants";
import axios from "axios";

// import { doPostRequest } from './request';

// Object.prototype.toType = function() {
//   return ({}).toString.call(this).match(/\s([a-zA-Z]+)/)[1].toLowerCase();
// };

String.prototype.getBytes = function () {
    var totalLen = 0;
    var charCode;
    for (var i = 0; i < this.length; i++) {
        charCode = this.charCodeAt(i);
        if (charCode < 0x007f) {
            totalLen++;
        } else if (charCode >= 0x0080 && charCode <= 0x07ff) {
            totalLen += 2;
        } else if (charCode >= 0x0800 && charCode <= 0xffff) {
            totalLen += 3;
        } else {
            totalLen += 4;
        }
    }
    return totalLen;
};

function drugseaUpgrade() {
    axios.get('/api2/get/latest/upgrage/files?noCache=true', {}).then((response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
    }).then((response) => {
        return response.data;
    }).then((response) => {
        const files = response.content;
        const tnum = files.length;
        let finishedCount = 0;
        let failedCount = 0;
        if (tnum > 0) {
            files.map((url) => {
                axios.get(url, {}).then((response) => {
                    finishedCount += 1;
                    if (response.statusText !== 'OK') {
                        failedCount += 1;
                    }
                    if (finishedCount + failedCount == tnum) {
                        console.log(`${failedCount}个文件升级失败，请重试。`);
                    }
                }).catch((error) => {
                    failedCount += 1;
                    console.log(error);
                    if (finishedCount + failedCount == tnum) {
                        console.log(`${failedCount}个文件升级失败，请重试。`);
                    }
                });
            });
        }
    });
}

function randomStr(length) {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            return false;
        }
    }
    return true;
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    //console.log('source=', source);

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, {
                        [key]: {}
                    });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {
                    [key]: source[key]
                });
            }
        }
    }

    return mergeDeep(target, ...sources);
}


function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

function deepMerge(target, source) {
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, {
                        [key]: {}
                    });
                }
                if (!isObject(source[key]) || !isObject(target[key])) {
                    Object.assign(target, {
                        [key]: source[key]
                    });
                } else {
                    mergeDeep(target[key], source[key]);
                }
            } else {
                Object.assign(target, {
                    [key]: source[key]
                });
            }
        }
    }

    return target;
}

function userFingerPrint() {
    // var options = {
    //   excludes: {
    //     userAgent: true,
    //     enumerateDevices: true,
    //     availableScreenResolution: true,
    //     audio: true
    //   }
    // };
    let dsufp = randomStr(36);
    const old_dsufp = getCookie("dsufp");
    // console.log(old_dsufp);
    setCookie("yaohai_domain", COOKIES_DOMAIN, {
        domain: COOKIES_DOMAIN,
        expires: 100,
        path: "/",
        samesite: "Lax"
    });
    if (typeof old_dsufp === "undefined") {
        setCookie("dsufp", dsufp, {
            domain: COOKIES_DOMAIN,
            expires: 100,
            path: "/",
            samesite: "Lax"
        });
    } else {
        dsufp = old_dsufp;
    }
    return dsufp;

    // Fingerprint2.getPromise(options).then((components) => {
    //   output_diff_objects(components);
    //   const values = components.map((component) => {
    //     return component.value;
    //   });
    //   const old_ufp = getLocalStorage('ufp');
    //   const murmur = Fingerprint2.x64hash128(values.join(''), 31);
    //   if (murmur !== old_ufp) {
    //     let old_fp = getLocalStorage('userFingerPrint');
    //     if (old_fp === '') {
    //       old_fp = '{}';
    //     }
    //     console.log(old_fp);

    //     const result = deepDiffMapper.map(components, JSON.parse(old_fp));

    //     console.log(result);
    //     setLocalStorage('ufp', murmur);
    //     // components[0].value = 'test';
    //     const new_fp = JSON.stringify(components);
    //     setLocalStorage('userFingerPrint', new_fp);
    //   } else {
    //     console.log('equal userFingerPrint: ' + murmur, components);
    //   }

    //   setCookie('dsufp', murmur, {
    //     'expires': 100,
    //     'path': '/'
    //   });
    //   return murmur;
    // });
}

function FilterList(list, filters) {
    const newList = [];
    list.map(row => {
        console.log('FilterList', row, filters);
        let Found = true;
        for (let key in filters) {
            const Values = filters[key];
            if (Values && Values.length > 0) {
                // const vals = [];

                let keyFound = false;
                console.log('FilterList', row, key)
                if (row[key]) {
                    const recordVals = row[key];
                    console.log('FilterList', Values, recordVals)
                    if (toType(recordVals) === 'string') {
                        if (Values.includes(recordVals)) {
                            keyFound = true;
                        }
                    } else if (toType(recordVals) === 'array') {
                        for (let line of recordVals) {
                            let lineVal;
                            if (toType(line) === 'string') {
                                lineVal = line;
                            } else if (toType(line) === 'object') {
                                lineVal = line.text;
                            }
                            if (Values.includes(lineVal)) {
                                keyFound = true;
                                break;
                            }
                        }
                    }
                    if (keyFound === false) {
                        Found = false;
                        break;
                    }
                    console.log('FilterList', keyFound, Found);
                } else {
                    Found = false;
                    break;
                }
            }
        }
        if (Found === true) {
            newList.push(row);
        }
    });
    console.log('FilterList', newList)
    return newList;
}

// function output_diff_objects(obj) {
//     const hash = hashCode(obj);
//     console.log("hash=", hash);
// }

// var deepDiffMapper = (function () {
//     return {
//         VALUE_CREATED: "created",
//         VALUE_UPDATED: "updated",
//         VALUE_DELETED: "deleted",
//         VALUE_UNCHANGED: "unchanged",
//         map: function (obj1, obj2) {
//             if (this.isFunction(obj1) || this.isFunction(obj2)) {
//                 throw "Invalid argument. Function given, object expected.";
//             }
//             if (this.isValue(obj1) || this.isValue(obj2)) {
//                 const type = this.compareValues(obj1, obj2);
//                 // const data = (obj1 === undefined) ? obj2 : obj1;
//                 const data = obj1 + "||" + obj2;
//                 if (type !== "unchanged") {
//                     console.log(type, data);
//                 }
//                 return {
//                     type: type,
//                     data: data
//                 };
//             }

//             var diff = {};
//             for (var key in obj1) {
//                 if (this.isFunction(obj1[key])) {
//                     continue;
//                 }

//                 var value2 = undefined;
//                 if (typeof obj2[key] !== "undefined") {
//                     value2 = obj2[key];
//                 }

//                 diff[key] = this.map(obj1[key], value2);
//             }
//             for (var key in obj2) {
//                 if (this.isFunction(obj2[key]) || typeof diff[key] !== "undefined") {
//                     continue;
//                 }

//                 diff[key] = this.map(undefined, obj2[key]);
//             }

//             return diff;
//         },
//         compareValues: function (value1, value2) {
//             if (value1 === value2) {
//                 return this.VALUE_UNCHANGED;
//             }
//             if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
//                 return this.VALUE_UNCHANGED;
//             }
//             if (typeof value1 === "undefined") {
//                 return this.VALUE_CREATED;
//             }
//             if (typeof value2 === "undefined") {
//                 return this.VALUE_DELETED;
//             }

//             return this.VALUE_UPDATED;
//         },
//         isFunction: function (obj) {
//             return {}.toString.apply(obj) === "[object Function]";
//         },
//         isArray: function (obj) {
//             return {}.toString.apply(obj) === "[object Array]";
//         },
//         isDate: function (obj) {
//             return {}.toString.apply(obj) === "[object Date]";
//         },
//         isObject: function (obj) {
//             return {}.toString.apply(obj) === "[object Object]";
//         },
//         isValue: function (obj) {
//             return !this.isObject(obj) && !this.isArray(obj);
//         }
//     };
// })();

// var result = deepDiffMapper.map({
//       a:'i am unchanged',
//       b:'i am deleted',
//       e:{ a: 1,b:false, c: null},
//       f: [1,{a: 'same',b:[{a:'same'},{d: 'delete'}]}],
//       g: new Date('2017.11.25')
//   },
//   {
//       a:'i am unchanged',
//       c:'i am created',
//       e:{ a: '1', b: '', d:'created'},
//       f: [{a: 'same',b:[{a:'same'},{c: 'create'}]},1],
//       g: new Date('2017.11.25')
//   });
// console.log(result);

// function obJectDiff(o1, o2) {
//     let diff = Object.keys(o2).reduce((diff, key) => {
//         const v1 = o1[key].value;
//         const v2 = o2[key].value;
//         console.log(typeof v1);
//         if (v1 === v2) return diff;
//         console.log("key = " + o2[key].key);
//         // console.log(o1[key].value);
//         // console.log(o2[key].value);
//         return {
//             ...diff,
//             [key]: o2[key]
//         };
//     }, {});
//     // console.log(diff);
//     return diff;
// }

function arrayDiff(a1, a2) {
    var a = [],
        diff = [];

    for (let i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
    }

    for (let i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
            delete a[a2[i]];
        } else {
            a[a2[i]] = true;
        }
    }

    for (var k in a) {
        diff.push(k);
    }

    return diff;
}

// 根据用户的查询参数，生成有意义的Title
const makeTitleFromParams = function (dbname, params) {
    const dbs = {
        jicai: "药品集采",
        jicai_mulu: "药品集采目录",
        cn_reference_drugs: "仿制药参比制剂目录",
        cn_reference_drugs_publicity: "仿制药参比制剂目录(公示)",
        cn_orange_book: "化学药品目录集",
        cde_yfb_registration: "原辅包登记信息公示",
        gov_drugs: "政府定价目录",
        nhsa_code: "医保药品分类与代码",
        yibao: "医保目录",
        jiyao: "基药目录",
        fda_ndc: "美国药品NDC数据库",
        fda_dmf: "美国DMF数据库",
        japan_dmf: "日本DMF数据库",
        drug_law: "药品法规知识库",
        medical_device: "国产器械(注册)",
        medical_device_jinkou: "进口器械(注册)",
        medical_device_beian: "国产器械(备案)",
        medical_device_jinkou_beian: "进口器械(备案)",
        zb_news: "全国招标新闻",
        se_notice: "医药上市公司公告",
        hma: "欧盟HMA上市药品",
        uk_emc: "英国上市药品",
        hk_doh: "香港上市药品",
        targets: "药物靶点数据库",
        nmpa_gmp: "GMP认证",
        nmpa_tcm_protection: "中药保护品种",
        nmpa_tcm_granules: "中药配方颗粒备案信息",
        nmpa_reg_patent: "药品注册相关专利信息",
        nhsa_herbs: "中药饮片信息",
        nhsa_hospital_prepration: "医疗机构制剂信息",
        drugsales: "全终端药品销售数据",
        nmpa_guochan: "国产药品",
        nmpa_jinkou: "进口药品",
        nmpa_tsspxx_gc: "国产保健食品注册",
        nmpa_tsspxx_jk: "进口保健食品注册",
        tw_fda: "台湾上市药品",
        zlsm: "药品专利声明",
        zldj: "药品专利信息公示",
        herbs: '中药材数据库',
        herb_formulas: '中药方剂数据库',
        cmchk_pcm: '香港注册中成药',
        dpd: '加拿大上市药品',
        yzpj_products: '一致性评价产品',
    };
    if (typeof dbs[dbname] !== "undefined") {
        dbname = dbs[dbname];
    }
    let Title = dbname;
    let titleStr = "";
    // 需要检查的关键字段
    const keys = [
        "title",
        "drug_name",
        "drug_name_cn",
        "active_substance",
        "ingredients",
        "brand_name",
        "sales_name",
        "product_name",
        "interventions",
        "enterprise",
        "company",
        "manufacture",
        "sales",
        "study_sponsor",
        "slh",
        "auth_num",
        "register_num",
        "ApplicationNo",
        "identifier",
        "category",
        "registered_drug_name",
        "trace_title",
        "tags",
        "city",
        "target_name"
    ];
    keys.forEach(function (item, index) {
        if (Object.prototype.hasOwnProperty.call(params, item) === true) {
            console.log("forEach", index)
            const val = params[item];
            if (val) {
                titleStr += val + ",";
            }
        }
    });
    const reg = /,$/;
    titleStr = titleStr.replace(reg, "");
    if (titleStr !== "") {
        Title = titleStr + " - " + Title;
    }

    const host = window.location.hostname;
    if (/pharmcube/.test(host) || /yiyaomofang/.test(host)) {
        Title = `药立方`;
    } else {
        Title = '药海遨游';
    }
    // console.log('Title', Title, params);
    // console.log(typeof (params));
    return Title;
};

// 获取localStorage中的键值
// const getLocalStorage = function (name) {
//     let val = "";
//     if (localStorageSupported()) {
//         val = window.localStorage[name];
//     }
//     if (typeof val === "undefined") {
//         val = "";
//     }
//     return val;
// };

// const setLocalStorage = function (name, val) {
//     if (typeof val === "undefined") {
//         val = "";
//     }
//     if (localStorageSupported()) {
//         try {
//             window.localStorage[name] = val;
//         } catch (e) {
//             if (e.name === "QuotaExceededError") {
//                 window.localStorage.clear();
//                 window.localStorage[name] = val;
//             }
//         }
//     }
// };

// const delLocalStorage = function (name) {
//     if (localStorageSupported() && typeof name !== "undefined") {
//         const val = window.localStorage[name];
//         if (typeof val !== "undefined") {
//             window.localStorage.removeItem(name);
//         }
//     } else {
//         window.localStorage.clear();
//     }
// };

// var gStorageSupported = "";

// function localStorageSupported() {
//     const testKey = "test";
//     if (gStorageSupported === "") {
//         try {
//             window.localStorage.setItem(testKey, "1");
//             window.localStorage.removeItem(testKey);
//             gStorageSupported = true;
//         } catch (error) {
//             gStorageSupported = false;
//             alert(
//                 "您的设备不支持localStorage。比如iPhone开启了无痕浏览模式，为了更好地体验，请关闭无痕浏览后重试。"
//             );
//         }
//     }
//     return gStorageSupported;
// }

function getBrowserType() {
    var ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    // alert('请在使用IE内核浏览器，若发现浏览问题或错误，建议使用火狐或Chrome等f非IE内核浏览器重试！');
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
}

function timeAgo(dt) {
    const lastDate = moment(dt, 'YYYY-MM-DD HH:mm:ss');
    const nowDate = moment();
    let timeago = '';
    const passedMinutes = nowDate.diff(lastDate, 'minutes');
    if (passedMinutes < 60) {
        timeago = passedMinutes + '分钟前更新';
    } else {
        const passedHours = nowDate.diff(lastDate, 'hours');
        if (passedHours < 24) {
            timeago = passedHours + '小时前更新';
        } else {
            const passedDays = nowDate.diff(lastDate, 'days');
            if (passedDays < 365) {
                timeago = passedDays + '天前更新';
            } else {
                const passedYears = nowDate.diff(lastDate, 'years');
                timeago = passedYears + '年前更新';
            }
        }
    }
    return timeago;
}

function getCurDate() {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();

    if (dd < 10) {
        dd = "0" + dd;
    }

    if (mm < 10) {
        mm = "0" + mm;
    }

    const curDate = yyyy + "-" + mm + "-" + dd;
    return curDate;
}

function inArray(search, array) {
    if (array.length > 0) {
        for (var i in array) {
            if (array[i] === search) {
                return true;
            }
        }
    }
    return false;
}

function getFileNameFromParams(params) {
    let name = "";
    const eF = ["action"];
    for (var key in params) {
        // console.log(key);
        if (Object.prototype.hasOwnProperty.call(params, key) && inArray(key, eF) === false) {
            if (params[key] != "") {
                name += params[key] + "-";
            }
        }
    }
    const maxL = 20;
    if (name.length > maxL) {
        name = name.substr(0, maxL) + "...";
    }
    return name;
}

function getOutputFileName(params, dbname) {
    const curDate = getCurDate();

    // 删除offset
    delete params.offset;
    delete params.buyit;
    // 剔除空属性
    params = pickBy(params, identity);

    const fileName = getFileNameFromParams(params);
    let fn = fileName + dbname + "_" + curDate + ".xlsx";
    return fn;
}

function getLocationOrigin() {
    if (!window.location.origin) {
        window.location.origin = window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "");
    }
    return window.location.origin;
}

/**
 * 获取屏幕宽度
 */
function getBodyWidth() {
    let width = 0;
    if (isServerSide()) {
        width = 1440;
    } else {
        width = document.getElementsByTagName("body")[0].clientWidth;
    }
    return width;
}
/**
 * 获取屏幕宽度
 */
function getBodyHeight() {
    let height = 0;
    if (isServerSide()) {
        height = 1440;
    } else {
        height = document.getElementsByTagName("body")[0].clientHeight;
    }
    return height;
}
/**
 * 获取唯一码
 */
function uuid() {
    return uuidv4();
}
/**
 * 用户是否登录
 */
function isUserLogin() {
    const unLogin = !Cookies.get(COOKIE_DS_UID_SIGN);
    return !unLogin;
}

/**
 * 是否是服务器端
 */
function isServerSide() {
    return typeof window === "undefined";
}
/**
 * 手动生成uuid
 */
function generateListXhuuid(list) {
    if (!list) {
        return [];
    }
    for (let i = 0; i < list.length; i++) {
        if (!list[i].xh_uuid) {
            list[i].xh_uuid = uuid();
        }
    }
    return list;
}
/**
 * obj 属性排序
 */
function objKeySort(obj) {
    // 排序的函数
    if (typeof obj === "object") {
        const newkey = Object.keys(obj).sort();
        // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
        const newObj = {}; // 创建一个新的对象，用于存放排好序的键值对
        for (let i = 0; i < newkey.length; i++) {
            // 遍历newkey数组
            newObj[newkey[i]] = obj[newkey[i]]; // 向新创建的对象中按照排好的顺序依次增加键值对
        }
        return newObj; // 返回排好序的新对象
    } else {
        return obj;
    }
}
Number.prototype.toHex = function () {
    var ret = ((this < 0 ? 0x8 : 0) + ((this >> 28) & 0x7)).toString(16) + (this & 0xfffffff).toString(16);
    while (ret.length < 8) ret = "0" + ret;
    return ret;
};

function stringify(o) {
    var i,
        r;
    if (o === null) return "n";
    if (o === true) return "t";
    if (o === false) return "f";
    if (o instanceof Date) return "d:" + (0 + o);
    i = typeof o;
    if (i === "string") return "s:" + o.replace(/([\\\\;])/g, "\\$1");
    if (i === "number") return "n:" + o;
    if (i === "undefined") return "undefined";
    if (o instanceof Function) return "m:" + o.toString().replace(/([\\\\;])/g, "\\$1");
    if (o instanceof Array) {
        o.sort();
        r = [];
        for (i = 0; i < o.length; i++) {
            r.push(stringify(o[i]));
        }
        return "a:" + r.join(";");
    }
    r = [];
    var keys = Object.keys(o),
        k,
        len = keys.length;
    keys.sort();
    for (i = 0; i < len; i++) {
        k = keys[i];
        r.push(i + ":" + stringify(o[k]));
    }
    return "o:" + r.join(";");
}

function hashCode(o, l) {
    l = l || 2;
    var i,
        c,
        r = [];
    for (i = 0; i < l; i++) {
        r.push(i * 268803292);
    }

    o = stringify(o);
    // console.log('O = ' + o)
    for (i = 0; i < o.length; i++) {
        for (c = 0; c < r.length; c++) {
            r[c] = (r[c] << 13) - (r[c] >> 19);
            r[c] += o.charCodeAt(i) << r[c] % 24;
            r[c] = r[c] & r[c];
        }
    }
    for (i = 0; i < r.length; i++) {
        r[i] = r[i].toHex();
    }
    return r.join("");
}

/**
 * 获取缓存data的key
 */
function getDataKey(params) {
    //console.log(params);
    // if (params.hasOwnProperty('tnum')) {
    //   delete params['tnum'];
    // }
    const trimedParams = trimAllRequestStringParams(objKeySort(params));
    // console.log(trimedParams);
    if (Object.prototype.hasOwnProperty.call(trimedParams, "tnum")) {
        delete trimedParams["tnum"];
    }
    var sorted = [];
    for (var key in trimedParams) {
        sorted[sorted.length] = key;
    }
    sorted.sort();
    var paramstr = "";
    for (var i = 0; i < sorted.length; i++) {
        paramstr += sorted[i] + "=" + trimedParams[sorted[i]];
    }
    // console.log(paramstr);
    // console.log('tPrams= ' + JSON.stringify(trimedParams));
    if (typeof trimedParams !== "undefined") {
        return `${hashCode(paramstr, 4)}`;
    } else {
        return "";
    }
}

/**
 * 用户组ID对应的title
 */
function Groupid2Title(gid) {
    if (gid == 9) {
        return "显示组";
    } else if (gid == 10) {
        return "免费用户";
    } else if (gid == 11) {
        return "编辑组";
    } else if (gid == 12) {
        return "企业版(销售)";
    } else if (gid == 13) {
        return "企业版(综合)";
    } else if (gid == 14) {
        return "企业版试用(注册)";
    } else if (gid == 15) {
        return "企业版(VIP)";
    } else if (gid == 201) {
        return "黄金版(注册)";
    } else if (gid == 202) {
        return "黄金版(销售)";
    } else if (gid == 203) {
        return "黄金版(综合)";
    } else if (gid == 205) {
        return "黄金版(VIP)";
    } else if (gid == 204) {
        return "黄金版试用(注册)";
    } else {
        return "未知用户";
    }
}

// 安全的JSON.parse
function parseJSON(str) {
    const re = /^\{/;
    const OK = re.exec(str);
    if (OK) {
        return JSON.parse(htmlDecodeByRegExp(str));
    } else {
        return str;
    }
}

function htmlDecodeByRegExp(str) {
    var s = "";
    if (str.length == 0) return "";
    s = str.replace(/&amp;/g, "&");
    s = s.replace(/&lt;/g, "<");
    s = s.replace(/&gt;/g, ">");
    s = s.replace(/&nbsp;/g, " ");
    s = s.replace(/&#39;/g, "'");
    s = s.replace(/&quot;/g, '"');
    return s;
}

/**
 * 获取用户信息
 */
function getUserInfo() {
    // 从 cookies 中获取名为 COOKIES_DS_USER_INFO 的用户信息
    const cookieUserInfo = Cookies.get(COOKIES_DS_USER_INFO);

    // 检查 cookieUserInfo 是否存在
    if (typeof cookieUserInfo !== "undefined" && cookieUserInfo !== "") {
        // 初始化一个空对象来存储用户信息
        let cUserInfo = {};
        // 检查 cookieUserInfo 是否是一个 JSON 字符串
        if (cookieUserInfo.indexOf("{") == 0) {
            // 如果是，直接解析为 JavaScript 对象
            cUserInfo = JSON.parse(cookieUserInfo);
        } else {
            // 如果不是，先进行 unescape 操作，然后解析为 JavaScript 对象
            cUserInfo = JSON.parse(decodeURIComponent(cookieUserInfo));
        }
        // 如果用户信息中包含 headimgurl 属性，将其 URL 协议从 http 替换为 https
        if (cUserInfo.headimgurl) {
            cUserInfo.headimgurl = cUserInfo.headimgurl.replace("http:", "https:");
        }
        // 如果用户信息中没有 admin_gid 属性，设置其值为 0
        cUserInfo.admin_gid = typeof cUserInfo.admin_gid !== "undefined" ? cUserInfo.admin_gid : 0;
        // 返回处理后的用户信息
        console.log('getUserInfo', cUserInfo);
        return cUserInfo;
    } else {
        // 如果没有找到用户信息，返回一个默认的用户信息对象
        const userInfo = {
            id: 0,
            uid: 0,
            first_name: "Default User",
            username: "",
            nickname: null,
            openid: "",
            unionid: "",
            invitor: null,
            headimgurl: "",
            province: "",
            city: "",
            country: "",
            sex: "1",
            language: "en",
            email: "",
            phone: "",
            position: "",
            name: null,
            company: "",
            groupid: 9,
            group_title: "",
            integral: 0,
            access_token: "",
            refresh_token: "",
            bonus_status: 0,
            subscribe: 0,
            subscribe_time: "",
            agent: "inFocus",
            admin_gid: 0
        };
        // 返回默认的用户信息
        return userInfo;
    }
}

/**
 * 设定用户cookie信息
 */
function setUserInfo(value) {
    Cookies.set(COOKIES_DS_USER_INFO, value, {
        domain: ".drugsea.cn"
    });
}

/**
 * 获取用户名 如果cookies不存在 返回null
 */

function getUserName() {
    const UserInfo = getUserInfo();
    if (UserInfo.id !== 0) {
        return UserInfo.username;
    } else {
        return null;
    }
}

function getUID() {
    const UserInfo = getUserInfo();
    if (UserInfo.id !== 0) {
        return UserInfo.uid;
    } else {
        return null;
    }
}

/**
 * 登录重定向
 */

function getRedirectUrl(location) {
    const res = `${location.pathname}?${qs.stringify(location.query)}`;
    if (res.indexOf("/?") == res.length - 2) {
        return res.substring(0, res.length - 2);
    }
    return res;
}
/**
 * 通过url 获取按照xxx浏览方式  默认选择按照下拉列表第一个选项进行搜索
 */
function getAviliableViewType(viewType, viewTypeList) {
    const res = viewTypeList.filter(obj => {
        return obj.value == viewType;
    });
    if (res && res.length > 0) {
        return viewType;
    } else {
        return viewTypeList[0].value;
    }
}
/**
 * 深度复制一个对象
 */

function clone(obj) {
    // Handle the 3 simple types, and null or undefined
    if (obj == null || typeof obj !== "object") return obj;

    // Handle Date
    if (obj instanceof Date) {
        const copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        const copy = [];
        const len = obj.length;
        for (let i = 0; i < len; ++i) {
            copy[i] = clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        const copy = {};
        for (const attr in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, attr)) {
                copy[attr] = clone(obj[attr]);
            }
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

/**
 * 清除请求中空key
 */
function trimAllRequestStringParams(obj) {
    // Handle the 3 simple types, and null or undefined
    // console.log(obj);
    if (typeof obj === "undefined") return "";
    if (obj == null) {
        return obj;
    }
    if (typeof obj === "string") {
        return obj.trim();
    }
    if (typeof obj !== "object") return obj;

    // Handle Date
    if (obj instanceof Date) {
        const copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        const copy = [];
        const len = obj.length;
        for (let i = 0; i < len; ++i) {
            copy[i] = clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        const copy = {};
        for (const attr in obj) {
            let val = obj[attr];

            if (typeof val === "undefined" || val == null || attr == "tnum") {
                continue;
            }
            // console.log(attr + ' = ' + val);
            if (typeof val === "string") {
                val = val.trim();
                if (val == "") {
                    continue;
                }
            }
            if (typeof val === "object") {
                copy[attr] = trimAllRequestStringParams(val);
            } else {
                copy[attr] = val.toString();
            }
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

/**
 * 获取当前选中的menu
 */
function getSelectedMenu(path, menuList) {
    if (!menuList || menuList.length == 0) {
        return null;
    }
    let item = null;
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].route == path) {
            item = menuList[i];
            break;
        } else if (menuList[i].subMenu && menuList[i].subMenu) {
            item = getSelectedMenu(path, menuList[i].subMenu);
            if (item) {
                break;
            }
        }
    }
    return item;
}

/**
 * 从 location.search, location.query中读取参数并合并
 */
function getLocationParams(location) {
    const urlParams = new URLSearchParams(location.search);
    const entries = urlParams.entries();
    const tempParams = location.query ? clone(location.query) : [];
    for (const entry of entries) {
        tempParams[entry[0]] = entry[1];
    }
    return tempParams;
}

/**
 * 从url中读取参数，返回json
 */
function getUrlParams(url) {
    var regex = /[?&]([^=#]+)=([^&#]*)/g,
        params = {},
        match;
    while ((match = regex.exec(url))) {
        params[match[1]] = decodeURIComponent(match[2]);
    }
    return params;
}

/**
 * 利用key 反向查询menu
 */
function getSelectedMenuByKey(key, menuList) {
    if (!menuList || menuList.length == 0) {
        return null;
    }
    let item = null;
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].key == key) {
            item = menuList[i];
            break;
        } else if (menuList[i].subMenu && menuList[i].subMenu) {
            item = getSelectedMenuByKey(key, menuList[i].subMenu);
            if (item) {
                break;
            }
        }
    }
    return item;
}

/**
 * 获取cookie值
 */

function getCookie(key) {
    const val = Cookies.get(key);
    return val;
}

/**
 * 更新cookie
 */
function setCookie(key, cookie, obj) {
    // console.log('setCookie', key, obj);
    if (!Object.prototype.hasOwnProperty.call(obj, "domain")) {
        obj.domain = COOKIES_DOMAIN;
    }
    console.log(key, cookie, obj, COOKIES_DOMAIN);
    Cookies.set(key, cookie, obj);
}
/**
 * 是否会被截断
 */
function isEllipsis(str, strMaxLength) {
    str = `${str}`;
    if (!str) {
        return false;
    }
    if (strMaxLength <= 0) {
        return false;
    }
    if (strMaxLength) {
        let newLength = 0;
        // Match non-ASCII characters. This example includes a range for CJK characters.
        // You might need to adjust it based on the specific characters you're targeting.
        const nonAsciiRegex = /[\u3000-\u9FFF\uF900-\uFAFF\u3400-\u4DBF]/g;
        let singleChar = "";
        const strLength = str.replace(nonAsciiRegex, "**").length;
        for (let i = 0; i < strLength; i++) {
            singleChar = str.charAt(i).toString();
            if (singleChar.match(nonAsciiRegex) != null) {
                newLength += 2;
            } else {
                newLength++;
            }
            if (newLength > parseInt(strMaxLength, 10) * 2 + 6) {
                return true;
            }
        }
    }
    return false;
}


/**
 * 获取被截取的字符串
 * maxLength 为中文字数，英文自动 * 2
 */
function getEllipsStr(str, maxLength) {
    str = `${str}`;
    if (!str) {
        return "";
    }
    if (maxLength <= 0) {
        return str;
    }
    if (maxLength) {
        let newLength = 0;
        let newStr = "";
        const nonAsciiRegex = /[\u3000-\u9FFF\uF900-\uFAFF\u3400-\u4DBF]/g;
        let singleChar = "";
        const strLength = str.replace(nonAsciiRegex, "**").length;
        for (let i = 0; i < strLength; i++) {
            singleChar = str.charAt(i).toString();
            if (singleChar.match(nonAsciiRegex) != null) {
                newLength += 2;
            } else {
                newLength++;
            }
            if (newLength > parseInt(maxLength, 10) * 2) {
                break;
            }
            newStr += singleChar;
        }

        if (strLength > parseInt(maxLength, 10) * 2) {
            newStr += "...";
        }
        return newStr;
    }
    return str;
}

function getCommaIntStr(a) {
    const result = [];
    let counter = 0;
    const num_array = a.split(".");
    const num = num_array[0];
    let newstr = "";
    for (let i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) {
            result.unshift(",");
        }
    }
    if (num_array.length > 1) {
        newstr = result.join("");
        return newstr;
    } else {
        return result.join("");
    }
}

/**
 * 治疗领域 特殊字符处理
 * return "消化道及代谢"             if ( $atc eq 'A' );
    return "血液和造血器官"          if ( $atc eq 'B' );
    return "心血管系统"                if ( $atc eq 'C' );
    return "皮肤病用药"                if ( $atc eq 'D' );
    return "生殖泌尿系统和性激素" if ( $atc eq 'G' );
    return "非性激素和胰岛素类的激素类系统用药"
      if ( $atc eq 'H' );
    return "系统用抗感染药"                   if ( $atc eq 'J' );
    return "抗肿瘤药和免疫机能调节药"    if ( $atc eq 'L' );
    return "肌肉-骨骼系统"                     if ( $atc eq 'M' );
    return "神经系统"                            if ( $atc eq 'N' );
    return "抗寄生虫药、杀虫药和驱虫药" if ( $atc eq 'P' );
    return "呼吸系统"                            if ( $atc eq 'R' );
    return "感觉器官"                            if ( $atc eq 'S' );
    return "杂类"                                  if ( $atc eq 'V' );
    return "原料药"                               if ( $atc eq 'W' );
    return "中药"                                  if ( $atc eq 'Z' );
    return "未知";
 */
function getAtcStr(code) {
    switch (code) {
        case "A":
            return "消化道及代谢";
        case "B":
            return "血液和造血器官";
        case "C":
            return "心血管系统";
        case "D":
            return "皮肤病用药";
        case "E":
            return "辅料";
        case "G":
            return "生殖泌尿系统和性激素";
        case "H":
            return "非性激素和胰岛素类的激素类系统用药";
        case "J":
            return "系统用抗感染药";
        case "L":
            return "抗肿瘤药和免疫机能调节药";
        case "M":
            return "肌肉-骨骼系统";
        case "N":
            return "神经系统";
        case "P":
            return "抗寄生虫药、杀虫药和驱虫药";
        case "R":
            return "呼吸系统";
        case "S":
            return "感觉器官";
        case "V":
            return "杂类";
        case "W":
            return "原料药";
        case "Z":
            return "中药";
        default:
            return "未知";
    }
}

function atc2name(atc) {
    if (toType(atc) === "array") {
        const Names = [];
        atc.map((a, i) => {
            console.log(i)
            const name = getAtcStr(a.text);
            Names.push(`${name}(${a.text})`);
        });
        return Names.join(";");
    } else {
        return getAtcName(atc);
    }
}

function getEntClassification(c) {
    if (c == 'R1') {
        return "R1: 体内放射性药品";
    } else if (c == 'R2') {
        return "R2: 体外放射性诊断试剂";
    } else if (c == 'C') {
        return 'C: 特殊药品';
    }
    const matched = c.match(/^([A-Z]{1})([a-z1-9]*)$/);
    const BG = {
        A: '自行生产的药品上市许可持有人',
        B: '委托生产的药品上市许可持有人',
        C: '接受委托的药品生产企业',
        D: '原料药生产企业',
        H: '化药',
        Z: '中成药',
        S: '生物制品',
        T: '按药品管理的体外诊断试剂',
        Y: '中药饮片',
        Q: '医用气体',
        F: '药用辅料',
        J: '空心胶囊',
        // C: '特殊药品',
        X: '其他',
        R: '放射性',
    };
    const SM = {
        h: '化药',
        z: '中成药',
        s: '生物制品',
        d: '按药品管理的体外诊断试剂',
        y: '中药饮片',
        q: '医用气体',
        t: '特殊药品',
        x: '其他',
        a: '原料药',
        b: '制剂',
        'r1': '体内放射性药品',
        'r2': '体外放射性诊断试剂'
    };
    if (matched) {
        const Big = matched[1];
        const Small = matched[2];
        let newC = '';
        if (Big) {
            if (Big in BG) {
                newC = `${c}: ${BG[Big]}`;
            } else {
                newC = `${c}: `;
            }
        }
        if (Small) {
            if (Small in SM) {
                newC += `(${SM[Small]})`;
            } else {
                newC += ``;
            }
        }
        return newC;
    } else {
        return c;
    }
}

function getAtcName(atc) {
    atc = atc.toUpperCase();
    const atcName = {
        A: "消化道及代谢",
        B: "血液和造血器官",
        C: "心血管系统",
        D: "皮肤病用药",
        E: "辅料",
        G: "生殖泌尿系统和性激素",
        H: "非性激素和胰岛素类的激素",
        J: "系统用抗感染药",
        L: "抗肿瘤药和免疫调节药",
        M: "肌肉-骨骼系统",
        N: "神经系统",
        P: "抗寄生虫药、杀虫药和驱虫药",
        R: "呼吸系统",
        S: "感觉器官",
        V: "杂类",
        W: "原料药",
        Z: "中药"
    };
    // console.log(atcName, atcName[atc]);
    if (typeof atcName[atc] !== "undefined") {
        return atc + ": " + atcName[atc];
    } else {
        return atc;
    }
}

// function getBytes(char) {
// }

function getATCList() {
    const res = [{
        value: "A",
        label: "消化道及代谢"
    }, {
        value: "B",
        label: "血液和造血器官"
    }, {
        value: "C",
        label: "心血管系统"
    }, {
        value: "D",
        label: "皮肤病用药"
    }, {
        value: "E",
        label: "辅料"
    }, {
        value: "G",
        label: "生殖泌尿系统和性激素"
    }, {
        value: "H",
        label: "非性激素和胰岛素类的激素"
    }, {
        value: "J",
        label: "系统用抗感染药"
    }, {
        value: "L",
        label: "抗肿瘤药和免疫调节药"
    }, {
        value: "M",
        label: "肌肉-骨骼系统"
    }, {
        value: "N",
        label: "神经系统"
    }, {
        value: "P",
        label: "抗寄生虫药、杀虫药和驱虫药"
    }, {
        value: "R",
        label: "呼吸系统"
    }, {
        value: "S",
        label: "感觉器官"
    }, {
        value: "V",
        label: "杂类"
    }, {
        value: "W",
        label: "原料药"
    }, {
        value: "Z",
        label: "中药"
    }];
    return res;
}

function getApplyTypeList() {
    const res = [{
        value: "新药",
        label: "新药"
    }, {
        value: "仿制",
        label: "仿制"
    }, {
        value: "进口",
        label: "进口"
    }, {
        value: "补充申请",
        label: "补充申请"
    }, {
        value: "进口再注册",
        label: "进口再注册"
    }, {
        value: "复审",
        label: "复审"
    }, {
        value: "已有国家标准",
        label: "已有国家标准"
    }];
    return res;
}
/**
 * 格式化文字
 */
function getFormatYName(str, maxLength, maxRows = 2) {
    if (!str) {
        return "";
    }
    let cons = "";
    let i = 0;
    let start = 0;
    const len = str.length;
    const bLen = str.getBytes();
    if (bLen <= maxLength) {
        cons = str;
    } else {
        let rows = 1;
        for (i = 0; i < len; i++) {
            let end = 1 + i;
            const s = str.substring(start, end);
            // console.log(start, end, s, cons);
            // console.log('s blen=', s.getBytes());
            if (s.getBytes() >= maxLength) {
                start = end;
                if (rows <= maxRows) {
                    cons = cons + s;
                    if (rows < maxRows) {
                        cons = cons + "\n";
                    } else if (rows == maxRows && i < len - 1) {
                        cons = cons + "..";
                    }
                }
                rows++;
            } else if (i === len - 1) {
                if (rows <= maxRows) {
                    cons = cons + s;
                }
                rows++;
            }
        }
    }
    return cons;
}

/**
 * 格式化文字
 */
function strCutOff(str, maxLength = 12) {
    //console.log("strCutOff", str, toType(str));
    if (toType(str) !== "string") {
        return str;
    }
    if (!str) {
        return "";
    }
    let cons = "";
    let i = 0;
    const len = str.length;
    const bLen = str.getBytes();
    if (bLen <= maxLength) {
        cons = str;
    } else {
        for (i = 0; i < len; i++) {
            const s = str.substring(0, i + 1);
            if (s.getBytes() >= maxLength) {
                cons = s;
                if (i < len - 1) {
                    cons = cons + "..";
                }
                break;
            }
        }
    }
    // console.log('strCutOff', cons, len, bLen, maxLength);
    return cons;
}

function getDrugTypeParent(drugType) {
    if (drugType == null || drugType == undefined) {
        return "";
    }
    if (drugType.indexOf("中药") >= 0) {
        return "中药";
    } else if (drugType.indexOf("化药") >= 0) {
        return "化药";
    } else if (drugType.indexOf("治疗用生物制品") >= 0) {
        return "治疗用生物制品";
    } else if (drugType.indexOf("预防用生物制品") >= 0) {
        return "预防用生物制品";
    } else {
        return "default";
    }
}

function getRegTypeByDrugType(drugType) {
    switch (drugType) {
        case "中药1类":
            return "1";
        case "中药2类":
            return "2";
        case "中药4类":
            return "4";
        case "中药5类":
            return "5";
        case "中药6类":
            return "6";
        case "中药6.1类":
            return "6.1";
        case "中药6.2类":
            return "6.2";
        case "中药7类":
            return "7";
        case "中药8类":
            return "8";
        case "中药9类":
            return "9";

        case "化药1类":
            return "1";
        case "化药1.1类":
            return "1.1";
        case "化药1.2类":
            return "1.2";
        case "化药1.3类":
            return "1.3";
        case "化药1.5类":
            return "1.5";
        case "化药1.6类":
            return "1.6";

        case "化药2类":
            return "2";
        case "化药2.1类":
            return "2.1";
        case "化药2.2类":
            return "2.2";
        case "化药2.3类":
            return "2.3";
        case "化药2.4类":
            return "2.4";

        case "化药3类":
            return "3";
        case "化药3.1类":
            return "3.1";
        case "化药3.2类":
            return "3.2";
        case "化药3.3类":
            return "3.3";
        case "化药3.4类":
            return "3.4";

        case "化药4类":
            return "4";
        case "化药5类":
            return "5";
        case "化药5.1类":
            return "5.1";
        case "化药5.2类":
            return "5.2";
        case "化药6类":
            return "6";

        case "治疗用生物制品1类":
            return "1";
        case "治疗用生物制品2类":
            return "2";
        case "治疗用生物制品3类":
            return "3";
        case "治疗用生物制品4类":
            return "4";
        case "治疗用生物制品5类":
            return "5";
        case "治疗用生物制品6类":
            return "6";
        case "治疗用生物制品7类":
            return "7";
        case "治疗用生物制品8类":
            return "8";
        case "治疗用生物制品9类":
            return "9";
        case "治疗用生物制品10类":
            return "10";
        case "治疗用生物制品11类":
            return "11";
        case "治疗用生物制品13类":
            return "13";
        case "治疗用生物制品14类":
            return "14";
        case "治疗用生物制品15类":
            return "15";

        case "预防用生物制品1类":
            return "1";
        case "预防用生物制品3类":
            return "3";
        case "预防用生物制品4类":
            return "4";
        case "预防用生物制品5类":
            return "5";
        case "预防用生物制品6类":
            return "6";
        case "预防用生物制品7类":
            return "7";
        case "预防用生物制品9类":
            return "9";
        case "预防用生物制品12类":
            return "12";
        case "预防用生物制品13类":
            return "13";
        case "预防用生物制品14类":
            return "14";
        case "预防用生物制品15类":
            return "15";
        default:
            return "";
    }
}

function drugTypeList() {
    const res = [{
        value: "中药",
        label: "中药",
        children: [{
            value: "中药1类",
            label: "中药1类"
        }, {
            value: "中药2类",
            label: "中药2类"
        }, {
            value: "中药4类",
            label: "中药4类"
        }, {
            value: "中药5类",
            label: "中药5类"
        }, {
            value: "中药6类",
            label: "中药6类"
        }, {
            value: "中药6.1类",
            label: "中药6.1类"
        }, {
            value: "中药6.2类",
            label: "中药6.2类"
        }, {
            value: "中药7类",
            label: "中药7类"
        }, {
            value: "中药8类",
            label: "中药8类"
        }, {
            value: "中药9类",
            label: "中药9类"
        }]
    }, {
        value: "化药",
        label: "化药",
        children: [{
            value: "化药1类",
            label: "化药1类"
        }, {
            value: "化药1.1类",
            label: "化药1.1类"
        }, {
            value: "化药1.2类",
            label: "化药1.2类"
        }, {
            value: "化药1.3类",
            label: "化药1.3类"
        }, {
            value: "化药1.5类",
            label: "化药1.5类"
        }, {
            value: "化药1.6类",
            label: "化药1.6类"
        }, {
            value: "化药2类",
            label: "化药2类"
        }, {
            value: "化药2.1类",
            label: "化药2.1类"
        }, {
            value: "化药2.2类",
            label: "化药2.2类"
        }, {
            value: "化药2.3类",
            label: "化药2.3类"
        }, {
            value: "化药2.4类",
            label: "化药2.4类"
        }, {
            value: "化药3类",
            label: "化药3类"
        }, {
            value: "化药3.1类",
            label: "化药3.1类"
        }, {
            value: "化药3.2类",
            label: "化药3.2类"
        }, {
            value: "化药3.3类",
            label: "化药3.3类"
        }, {
            value: "化药3.4类",
            label: "化药3.4类"
        }, {
            value: "化药4类",
            label: "化药4类"
        }, {
            value: "化药5类",
            label: "化药5类"
        }, {
            value: "化药5.1类",
            label: "化药5.1类"
        }, {
            value: "化药5.2类",
            label: "化药5.2类"
        }, {
            value: "化药6类",
            label: "化药6类"
        }]
    }, {
        value: "治疗用生物制品",
        label: "治疗用生物制品",
        children: [{
            value: "治疗用生物制品1类",
            label: "治疗用生物制品1类"
        }, {
            value: "治疗用生物制品2类",
            label: "治疗用生物制品2类"
        }, {
            value: "治疗用生物制品3类",
            label: "治疗用生物制品3类"
        }, {
            value: "治疗用生物制品4类",
            label: "治疗用生物制品4类"
        }, {
            value: "治疗用生物制品5类",
            label: "治疗用生物制品5类"
        }, {
            value: "治疗用生物制品6类",
            label: "治疗用生物制品6类"
        }, {
            value: "治疗用生物制品7类",
            label: "治疗用生物制品7类"
        }, {
            value: "治疗用生物制品8类",
            label: "治疗用生物制品8类"
        }, {
            value: "治疗用生物制品9类",
            label: "治疗用生物制品9类"
        }, {
            value: "治疗用生物制品10类",
            label: "治疗用生物制品10类"
        }, {
            value: "治疗用生物制品11类",
            label: "治疗用生物制品11类"
        }, {
            value: "治疗用生物制品13类",
            label: "治疗用生物制品13类"
        }, {
            value: "治疗用生物制品14类",
            label: "治疗用生物制品14类"
        }, {
            value: "治疗用生物制品15类",
            label: "治疗用生物制品15类"
        }]
    }, {
        value: "",
        label: "",
        children: [{
            value: "预防用生物制品1类",
            label: "预防用生物制品1类"
        }, {
            value: "预防用生物制品3类",
            label: "预防用生物制品3类"
        }, {
            value: "预防用生物制品4类",
            label: "预防用生物制品4类"
        }, {
            value: "预防用生物制品5类",
            label: "预防用生物制品5类"
        }, {
            value: "预防用生物制品6类",
            label: "预防用生物制品6类"
        }, {
            value: "预防用生物制品7类",
            label: "预防用生物制品7类"
        }, {
            value: "预防用生物制品9类",
            label: "预防用生物制品9类"
        }, {
            value: "预防用生物制品12类",
            label: "预防用生物制品12类"
        }, {
            value: "预防用生物制品13类",
            label: "预防用生物制品13类"
        }, {
            value: "预防用生物制品14类",
            label: "预防用生物制品14类"
        }, {
            value: "预防用生物制品15类",
            label: "预防用生物制品15类"
        }]
    }];
    return res;
}

/**
 * 数组中是否包含某元素
 */
function contains(arr, obj) {
    let i = arr.length;
    while (i--) {
        if (arr[i] === obj) {
            return true;
        }
    }
    return false;
}

function toType(obj) {
    return {}.toString
        .call(obj)
        .match(/\s([a-zA-Z]+)/)[1]
        .toLowerCase();
}

function addLocationSearch(obj) {
    const searchParams = Object.assign({}, obj.query);
    if (Object.keys(searchParams).length > 0) {
        const searchStr = '?' + queryString.stringify(searchParams);
        return Object.assign(obj, {
            search: searchStr
        });
    } else {
        return obj;
    }
}


function checkInputValue(type, val) {
    var re = {
        'email': /^\w+(\.)?(\w+)?@[0-9a-z]+(\.[a-z]+){1,3}$/,
        'phone': /^((13[0-9])|(15[0-37-9])|(18[0235789])|(14[57]))\d{8}$/,
        'idcard': /^[1-9]((\d{14})|(\d{17})|(\d{16}x))$/i,
        'website': /^[a-zA-Z]+:\/\/[^\s]*$/,
        'qq': /^[1-9][0-9]{4,10}$/,
        'zipcode': /^[1-9]\d{5}$/,
        'chinese': /^[\u4e00-\u9fa5]+$/,
        'openid': /^oMdqBw.+$/
    };
    if (typeof re[type] !== 'undefined') {
        return re[type].test(val);
    } else {
        console.log("checkInputValue unknown TYPE:", type);
        return false;
    }
}


export { drugseaUpgrade, checkInputValue, deepMerge, mergeDeep, FilterList, addLocationSearch, getLocationParams, timeAgo, atc2name, toType, getUID, arrayDiff, isEmpty, makeTitleFromParams, parseJSON, strCutOff, getAtcName, getEntClassification, userFingerPrint, getOutputFileName, getFileNameFromParams, getCurDate, getBrowserType, getUrlParams, hashCode, getLocationOrigin, trimAllRequestStringParams, getSelectedMenu, getSelectedMenuByKey, getAviliableViewType, clone, uuid, generateListXhuuid, isUserLogin, getUserInfo, setUserInfo, getUserName, Groupid2Title, setCookie, getCookie, getDataKey, getRedirectUrl, getEllipsStr, isEllipsis, getAtcStr, getATCList, getApplyTypeList, getRegTypeByDrugType, drugTypeList, getDrugTypeParent, contains, getCommaIntStr, getBodyWidth, isServerSide, getBodyHeight, getFormatYName };