import { message } from 'ant-design-vue';
import axios from "axios";
import qs from "qs";
// import { BASE_HOST, CODE_UN_LOGIN, SUCCESS } from "./constants";
import { CODE_UN_LOGIN, SUCCESS } from "./constants";
import { getDataKey, getUserInfo, trimAllRequestStringParams, toType } from "./Util";
import { dsEncode, dsDecode } from "./encrypt";


axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
/**
 * 请求实际调用axios来进行分发。可以解决跨域问题
 * @param {} url
 * @param {*} options
 */

// 全局请求计数器
const requestCounter = {
    post: 0,
    get: 0
};
// // 定时输出请求次数，并重置计数器
// setInterval(() => {
//     console.log(`POST请求次数: ${requestCounter.post}`);
//     console.log(`GET请求次数: ${requestCounter.get}`);
//     // 重置计数器
//     requestCounter.post = 0;
//     requestCounter.get = 0;
// }, 1000); // 每秒输出一次请求次数

const fetchAxios = (url, options) => {
    // 从options对象中解构出method和data属性
    // 如果options对象中没有method属性，那么默认将其设置为"get"
    const { method = "get", data } = options;
    const cloneData = data;
    switch (method.toLowerCase()) {
        case "get":
            return axios.get(url, {
                params: cloneData
            });
        case "get_file": {
            return axios({
                method: "get",
                url,
                params: cloneData,
                responseType: "stream"
            });
        }
        case "delete":
            return axios.delete(url, cloneData);
        case "post":
            return axios.post(url, cloneData);
        case "put":
            return axios.put(url, cloneData);
        case "patch":
            return axios.patch(url, cloneData);
        default:
            return axios(options);
    }
};

function getLocalStorage(options) {
    const curTime = (Date.now() / 1000) | 0;
    if (window.localStorage) {
        const dataStr = window.localStorage.getItem(options.key);
        if (dataStr) {
            const data = JSON.parse(dataStr);
            const oldData = data.data.data;
            if (toType(oldData) == 'string') {
                data.data.data = JSON.parse(dsDecode(oldData));
            }
            const lastTTL = typeof data.data.data.ttl !== "undefined" ? data.data.data.ttl : 0;
            const lastTime = typeof data.timestamp !== "undefined" ? data.timestamp : 0;
            const ttl = lastTTL - (curTime - lastTime);
            const dataObject = typeof data.data.data !== "undefined" ? data.data.data : null;
            if (ttl > 0) {
                return {
                    data: dataObject,
                    ttl: ttl
                };
            } else {
                // 已过期
                return {
                    data: dataObject,
                    ttl: ttl
                };
            }
        } else {
            // 本地无缓存
            return null;
        }
    } else {
        // 浏览器不支持缓存
        return null;
    }
}

function saveLocalStorage(key, data) {
    const curTime = (Date.now() / 1000) | 0;
    if (typeof data.ttl === "undefined" || data.ttl === 0) {
        return true;
    }
    const saveData = {
        data: {
            data: dsEncode(JSON.stringify(data))
        },
        timestamp: curTime
    };
    const dataStr = JSON.stringify(saveData);

    if (window.localStorage) {
        try {
            window.localStorage.setItem(key, dataStr);
        } catch (e) {
            if (e.name === "QuotaExceededError") {

                window.localStorage.clear();
                window.localStorage.setItem(key, dataStr);
            }
        }
        return true;
    } else {
        // 浏览器不支持缓存
        return null;
    }
}

/**
 * {
 *  "api_status":"success",// "success " || "fail"
 *  "content":any, //可以为数组也可以为对象
 *  "code":0 ,//  错误码，0 为成功，其他为失败
 * }
 */

/**
 * 默认讲请求转为json格式
 */
function parseJSON(response) {
    return response.data;
}

/**
 * 格式化为固定格式的data
 */

function parseFormatData(data) {
    //console.log('request data', data);
    if (data.data && toType(data.data) == 'string') {
        data = JSON.parse(dsDecode(data.data));
        //console.log('request', data);
    } else {
        //console.log('parseFormatData', data);
    }
    if (data.api_status == SUCCESS) {
        data.success = true;
    } else {
        data.success = false;
    }
    if (data.code == CODE_UN_LOGIN) {
        // redirect login
        const error = new Error("网络请求错误 ");
        error.code = CODE_UN_LOGIN;
        throw error;
    } else if (data.code > 0) {
        message.error(data.content, 5);
    }
    return data;
}

/**
 * 检查 http请求返回码是否是200 否则失败
 */
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error("网络请求错误 ");
    error.response = response;
    throw error;
}

/**
 * 初始化请求的option
 * 可以设置cookie或者其他token之类的内容
 */

function initOptions(params, method) {
    const options = {
        method: method,
        data: params
    };
    return options;
}

/**
 * post请求封装
 */
function doPostRequest(url, params) {
    requestCounter.post++;
    return request(url, initOptions(params, "POST"));
}
/**
 * get请求封装
 */
function doGetRequest(url, params) {
    requestCounter.get++;
    return request(url, initOptions(params, "GET"));
}
/**
 * 下载文件封装
 */
function doGetFile(url, params) {
    if (params) {
        url = `${url}?${qs.stringify(trimAllRequestStringParams(params))}`;
    }
    const options = {
        method: "GET",
        credentials: "include",
        "Content-Type": "application/json"
    };

    return getFile(url, options);
}

/**
 * 下载文件封装
 * @param {} url
 * @param {*} options
 */
function getFile(url, options) {
    return fetch(url, options)
        .then(checkStatus)
        .then(data => {
            return data.blob();
        });
}
/**
 * 上传文件封装
 */
function doUploadFile(url, params) {
    const options = initOptions(params, "POST");
    return request(url, options);
}
/*
 *  构建一个只返回输入值的Promise，
 *  使request在返回cache data时，返回的也为Promise对象
 *
 */
function dataCacheFunction(data) {
    return new Promise((resolve) => {
        resolve(data);
        // console.log("reject", reject)
    });
}
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

function request(url, options) {

    const noCache = typeof options.data.noCache !== "undefined" ? options.data.noCache : false;
    // console.log('BASE_HOST', BASE_HOST);
    //delete options.data.noCache
    if (options.data.noCache) {
        delete options.data.noCache;
    }

    // console.log('request', options, url);
    const userInfo = getUserInfo();
    // console.log('userInfo', userInfo);
    const admin_gid = typeof userInfo.admin_gid !== "undefined" ? userInfo.admin_gid : 0;
    //不通过代理，直接访问后端
    // if (url.indexOf("/") == 0) {
    //     // url = `${BASE_HOST}${url}`;
    // }
    // 生成api链接的唯一key
    const newOptions = {
        url: url
    };
    const params = {
        ...options.data,
        ...newOptions
    };
    // delete params.noCache;
    const key = "ds_" + getDataKey(params);

    if (!noCache) {
        // timeout>0时，先检查有无缓存，若有有效期内的缓存就返回
        const cachedData = getLocalStorage({
            key
        });
        if (cachedData) {
            const data = cachedData.data;
            const ttl = cachedData.ttl;
            // 只返回没有过期的本地缓存 
            const cachedGroupid = typeof data.admin_gid !== "undefined" ? data.admin_gid : 0;
            // 如果ttl（Time To Live）大于0，表示缓存数据仍然有效
            if (ttl > 0) {
                // 打印日志，显示返回的旧缓存数据和相关信息
                console.log('old cached returned:' + url + ' cachedData=', key, data, options);
                // 使用dataCacheFunction处理数据，这可能是一个异步操作
                return dataCacheFunction(data).then(data => {
                    return {
                        data
                    };
                });
            } else {
                console.log(ttl, cachedGroupid, admin_gid);
            }
        }
    }
    console.log('new request', url, options);
    return fetchAxios(url, options)
        .then(checkStatus)
        .then(parseJSON)
        .then(parseFormatData)
        .then(data => {
            // console.log(data);
            // console.log('fetching new ', data);
            // resetDomain(url);
            if (data.api_status === "success") {
                // 保存到本地缓存default 100s
                data.ttl = 100;
                console.log('save to LocalStorage', key, data);
                saveLocalStorage(key, data);


                return {
                    data
                };
            }
            return {
                data
            };
        });
}

export { doPostRequest, doGetRequest, doGetFile, doUploadFile };