<template>

   <div v-if="isLoading.value" class="loading-overlay">
      <a-spin spinning></a-spin>
    </div>
  <nav>
    <a-layout>
      <a-layout-header :style="{ padding: 0, display: 'flex', justifyContent: 'flex-end', maxWidth: '100%', overflowX: 'auto'}">
        <a-menu mode="horizontal" theme="white">
          <!-- 显示groupname -->
          <a-menu-item key="0">
            <span style="color: rgb(255, 255, 255); display: inline-block; width: 100px; height: 50px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ usernickname }}</span>
            <!-- <span style="color: rgb(255, 255, 255); display: inline-block; width: 100px; height: 50px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{ groupname }}</span> -->
          </a-menu-item>
          <a-menu-item>
            <img :src="profile_picture" alt="Profile picture" width="50" height="50">
          </a-menu-item>

          <a-menu-item key="1">
            <router-link :to="{name:'APIlist'}">API list</router-link>
          </a-menu-item>
          <!-- <a-menu-item key="2">
            <router-link to="/about">About</router-link>
          </a-menu-item> -->
          <a-menu-item key="3">
            <router-link :to="{name:'riskmatrix'}">riskmatrix</router-link>
          </a-menu-item>
          <a-menu-item key="4">
            <router-link to="/loginout">Sign out</router-link>
          </a-menu-item>
          <!-- Add more menu items here if needed -->
        </a-menu>
      </a-layout-header>
    </a-layout>
  </nav>
  <router-view/>
</template>

<script setup>
import { RouterLink } from 'vue-router';
// import {Groupid2Title} from "@/utils/Util";
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const usernickname= computed(() => store.getters.usernickname);
const profile_picture= computed(() => store.getters.profile_picture);
const isLoading = computed(() => store.state.isLoading);
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a-layout-header {
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
}

a-menu {
  line-height: 64px;
  white-space: nowrap; /* Ensure menu items stay in one line */
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

/* Optional: Hide horizontal scrollbar */
a-layout-header::-webkit-scrollbar {
  display: none;
}

a-layout-header {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.loading-overlay {
  position: fixed; /* 固定定位，覆盖整个屏幕 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5); /* 半透明背景 */
  z-index: 1000; /* 确保遮罩层在顶层 */
}
</style>
