import { Modal } from 'ant-design-vue';
import { doPostRequest } from '@/utils/request';
const state = {
    genotoxicityData: [],
};


const mutations = {
    SET_GENO_DATA(state, data) {
        state.genotoxicityData = data;
    },
};
const actions = {
    async fetchgenotoxicityData({ commit, dispatch, rootState }, newData) {
        try {
            const response = await doPostRequest('/hkpma/toxicity/studies',
                newData
            );
            commit('SET_GENO_DATA', response.data.content);
            //取消这部分loading
            if (rootState.isLoading.api === "APIDetailpage") {
                rootState.APIdetail.genotoxicityData = true;
                dispatch('checkAndSetLoadingFalse', null, { root: true });

            }
            console.log("fetchgenotoxicityData response", response.data.content)
        } catch (error) {
            console.error('Error fetching other tox data:', error);
        }
    },
    async addgenotoxicityStudy({ dispatch }, newData) {
        try {
            //判断是否有空值
            if (newData.route === '' || newData.species === '' || newData.minimum_dose === '' || newData.minimum_dose_unit === '') {
                Modal.warning({
                    title: 'Warning',
                    content: 'Please fill in all the fields',
                });
                return;
            }
            //修改的时候新增字段nocache为true
            if (newData.noCache === undefined) {
                newData.noCache = true;
            }
            const response = await doPostRequest('/hkpma/toxicity/studies', newData);
            console.log("addStudyDataresponse", response)
            if (response.status === 200) {
                //获得数据
                var payload = {
                    option: "get",
                    APID: newData.APID,
                    toxicity_type: "genotoxicity",
                    noCache: true
                };
                await dispatch('fetchgenotoxicityData', payload);
                Modal.success({
                    title: 'write to database',
                    content: [response.data.api_status,]
                });
            } else {
                // Handle cases where response is not 200
                // This could be showing an error message or performing some other logic
                console.error("Failed to add study data, response status:", response.status);
                // Optionally, you can use Modal.error to show an error message
                Modal.error({
                    title: 'Failed to write to database',
                    content: 'An error occurred while trying to add study data.'
                });
            }
        } catch (error) {
            console.error('Error adding Study data:', error);
            Modal.warning({
                title: 'Warning',
                content: error.message,
            });
        }
    },
    async deletegenotoxicityStudy({ dispatch }, newData) {
        try {
            console.log("deletegenotoxicityStudy", newData)
            //修改的时候新增字段nocache为true
            newData.noCache = true;
            await doPostRequest('/hkpma/toxicity/studies', newData);
            var payload = {
                option: "get",
                APID: newData.APID,
                toxicity_type: "genotoxicity",
                noCache: true
            };
            dispatch('fetchgenotoxicityData', payload);
        } catch (error) {
            console.error('Error deleting Study data:', error);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};