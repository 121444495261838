
import { Buffer } from 'buffer/';

const dsEncode = function (inStr) {
    const r = Buffer.from(inStr);
    let content = r.toString('base64');
    const p1 = Math.floor(content.length / 2);
    const arr = content.split('');
    for (var i = 0; i < p1; i += 2) {
        const tmp = arr[i];
        arr[i] = arr[p1 + i];
        arr[p1 + i] = tmp;
    }
    return arr.join('');
};

const dsDecode = function (inStr) {
    const arr = inStr.split('');
    const p1 = Math.floor(inStr.length / 2);
    for (var i = 0; i < p1; i += 2) {
        const tmp = arr[i];
        arr[i] = arr[p1 + i];
        arr[p1 + i] = tmp;
    }
    const r = Buffer.from(arr.join(''), 'base64');
    const content = r.toString();
    return content;
};

const bs64Encode = function (inStr) {
    const r = Buffer.from(inStr);
    return r.toString('base64');
}

const bs64Decode = function (inStr) {
    const r = Buffer.from(inStr, 'base64');
    return r.toString();
}

export { dsEncode, dsDecode, bs64Encode, bs64Decode };