import { createRouter, createWebHistory } from 'vue-router'
import { getToken } from '@/utils/auth'
// 定义子路由
// 定义 APIlist 的子路由，包括 Detail 作为子路由
const apiListChildrenRoutes = [
  {
    path: 'Detail/:APID', // 定义为相对路径，作为APIlist的子路由
    name: 'Detail',
    component: () => import('../show/DetailLayerview.vue'),
    meta: {
      title: 'Detail'
    }
  }
];
const homeChildrenRoutes = [
  {
    path: 'riskmatrix',
    name: 'riskmatrix',
    component: () => import('../show/RiskMatrixview.vue'),
    meta: {
      title: 'riskmatrix'
    }
  },
  {
    path: 'APIlist',
    name: 'APIlist',
    component: () => import('../show/APIlistview.vue'),
    meta: {
      title: 'APIlist'
    },
    children: apiListChildrenRoutes, // 将 Detail 作为 APIlist 的子路由
    // 添加默认或空子路由以确保APIlist能独立显示
    //redirect: { name: 'APIlist' }
  },

]

const rootChildrenRoutes = [
  {
    path: 'home',
    name: 'home',
    redirect: '/home/APIlist',
    children: homeChildrenRoutes
  },
  {
    path: 'login',
    name: 'login',
    component: () => import('../show/LoginView.vue')
  },
  {
    path: 'loginout',
    name: 'loginout',
    component: () => import('../show/LoginOut.vue')
  },
]

const routes = [
  {
    path: '/',
    redirect: '/home', // 默认重定向到 /home
  },
  {
    path: '/',
    name: 'root',
    children: rootChildrenRoutes,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = getToken();
  if (token) {
    if (to.path === '/login') {
      next('/home')
    } else {
      next();
    }
  } else {
    if (to.path === '/login') {
      next();
    } else {
      next('/login')
    }
  }
});

export default router;
