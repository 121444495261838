import Cookies from 'js-cookie'
const TokenKey = 'UserInfo'
const UserInfoKey = 'UserInfo'

export function getToken() {
    console.log(Cookies)
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}
export function removeAllCookies() {
    var allCookies = Cookies.get();
    console.log(allCookies)
    for (var cookie in allCookies) {
        // Cookies.remove(cookie, { domain: 'localhost', path: '/' }); // 指定域和路径
        Cookies.remove(cookie); // 不指定域和路径
        Cookies.remove(cookie, { domain: '.drugsea.com' }); // 删除根域的 cookie

    }
}

export function getUserInfo() {
    try {
        var t = Cookies.get(UserInfoKey)
        console.log(t)
        if (t) {
            t = JSON.parse(t)
        }
    } catch (e) {
        // console.log("Jason")
        console.log(e)
    }
    return t // Cookies.get("probot_userInfo")
}

export function setUserInfo(data) {
    return Cookies.set(UserInfoKey, data) // Cookies.set("probot_userInfo", JSON.stringify(data))
}
